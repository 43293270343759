import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl, AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { CookieService } from 'ngx-cookie-service';
import { ToastrService } from 'ngx-toastr';
import { RequestPayload } from 'src/app/models/request-payload';
import { ApiConfigurationService } from 'src/app/services/api-configuration.service';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { GlobalMessagesService } from 'src/app/services/global-messages.service';
import { LocalService } from 'src/app/services/local.service';
import { LoaderService } from '../loader/loader.service';
import { DomSanitizer } from '@angular/platform-browser';
import { NameWhiteSpace } from 'src/app/services/nameWhiteSpace.validator';
import { CommonService } from 'src/app/services/common.service';
import { MatDialog } from '@angular/material/dialog';
import { WarningDailogComponent } from '../warning-dailog/warning-dailog.component';

declare var $;

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit{
  loginForm:FormGroup;
  errorValidationMsgs:any;
  requestPayload:RequestPayload=Object();
  fPasswordForm: FormGroup;
  resetPasswordForm: FormGroup;
  loginFormValidations: any;
  responsesMessage: any;
  loginBlock:boolean=true;
  errorMsg:any="";
  showPwd:boolean=false;
  showPwd1:boolean=false;
  resetBlock:boolean=false;
  tokenReceivedThroughEmail: any;
  userId: any;
  queryParamKeyVal: string | undefined;
  url:any="";
  isCaptcha:boolean=false;
  mobileNum: any;
  showOTPBlock: boolean | undefined;
  otpMsg: string | undefined;
  formTitle:string = "Sign In";
  resetPWDSuccessMsg:boolean = false;
  successMsg:any="";
  warningText:any="";
  constructor(private formBuilder: FormBuilder, private messageProperty:GlobalMessagesService,
    private environment:ApiConfigurationService,private authenticationService:AuthenticationService,
    private router:Router,private cookieService:CookieService,private toastrService:ToastrService,
    private loaderService:LoaderService,private localService:LocalService,private translate: TranslateService,
    private route: ActivatedRoute,private sanitizer:DomSanitizer, private commonService:CommonService,
    private dailog:MatDialog) {
      this.errorValidationMsgs=this.messageProperty['loginFormValidations'];
      this.loginFormValidations=this.messageProperty.loginFormValidations;
      this.loginForm = this.formBuilder.group({
        email:['',Validators.compose([
                Validators.required,
              NameWhiteSpace.noSpaceAllowed])],
        password:['', [Validators.required,
          NameWhiteSpace.noSpaceAllowed]],
        userTypeId:['',[Validators.required]],
        captcha:[''],
        captchaCode:['']
      });
      this.fPasswordForm = new FormBuilder().group(
        {
        userId:['', [Validators.required,NameWhiteSpace.noSpaceAllowed]],
        email: ['', [ Validators.required,
          NameWhiteSpace.noSpaceAllowed,Validators.pattern('^[_A-Za-z0-9-\\+]+(\\.[_A-Za-z0-9-]+)*@[A-Za-z0-9-]+(\\.[A-Za-z0-9]+)*(\\.[A-Za-z]{2,})$')]],
      });
    
      this.resetPasswordForm = this.formBuilder.group({
        'password': ['',Validators.compose([
          Validators.required,
          this.patternValidator(/\d/, { hasNumber: true }),
          this.patternValidator(/[A-Z]/, { hasCapitalCase: true }),
          this.patternValidator(/[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/,{ hasSpecialCharacters: true}),
          Validators.minLength(8),
          NameWhiteSpace.noSpaceAllowed
        ])],
        'confirmPassword': ['', Validators.compose([Validators.required,NameWhiteSpace.noSpaceAllowed])]
      },
      { validator: this.passwordMatchValidator }
      );

      document.body.classList.add('overflow-hidden');
      document.body.classList.add('login-footer');
     }

  ngOnInit(): void {
    let token:any=this.cookieService.get('token') != null ? this.cookieService.get('token') : "";
    if(token!='' && !this.router.url.includes('resetPassword')){
      if(this.localService.getJsonValue("bpId")!=undefined){
        //this.router.navigate(['/applaunchpad']);
      }else{
        this.localService.setJsonValue('orgId',null);
        //this.router.navigate(['/landingpage']);
      }
    }else{
      this.localService.clearToken();
      localStorage.clear();
    }
    this.authenticationService.setCurrentOrgId(null);
    let key = '';
    if(this.router.url.includes('resetPassword')){
        this.resetBlock=true;
        this.loginBlock=false;
        this.formTitle = "Change Password";
        this.route.queryParams.subscribe(params => {
          for (key in params) {
          }
          this.queryParamKeyVal = key;
          if (key == 'ky') {
            this.tokenReceivedThroughEmail = params[key];
          }
          if (key == 'orgky') {
            this.tokenReceivedThroughEmail = params[key];
            $('.title-header').text('Create Password');
          }
        });
        if (this.tokenReceivedThroughEmail === undefined) {
          this.tokenReceivedThroughEmail = "";
        }
        if (this.tokenReceivedThroughEmail != "") {
          this.resetPassword(key);
        } else {
          if (key == 'ky') {
            const path: string = 'login';
            this.router.navigate([path]);
          }
        }
      }else{
        this.loginBlock=true;
        this.resetBlock=false;
      }
    this.checkCaptcha();
  }
  checkCaptcha(){
    let url:any=this.environment.getRequiredApi("get_authenticated_captcha");
    this.loaderService.loadShow();
    this.requestPayload=Object();
    this.requestPayload.url=url;
    this.authenticationService.getCaptcha(this.requestPayload).subscribe(res=>{
      if(res!=null && res.status==0){
        if(res.hasOwnProperty('data')){
         this.isCaptcha=res.data.isCaptchaEnabled;
         if(this.isCaptcha){
          this.loginForm?.get('captcha')?.setValidators([Validators.required]);
          this.loginForm?.get('captcha')?.updateValueAndValidity();
          this.loginForm.get('captchaCode')?.setValidators([Validators.required]);
          this.loginForm.get('captchaCode')?.setValue(res.data.captchaString);
          this.loginForm.get('captchaCode')?.updateValueAndValidity();
         }else{
           this.loginForm.get('captcha')?.clearValidators();
           this.loginForm.get('captcha')?.updateValueAndValidity();
           this.loginForm.get('captchaCode')?.clearValidators();
           this.loginForm.get('captchaCode')?.updateValueAndValidity();
         }
         let url:any='data:image/jpeg;base64,'+res.data.image;
         this.url=this.sanitizer.bypassSecurityTrustUrl(url);
   
        }
      }
      this.loaderService.loadHide();
    })
   }
   verifyCaptcha(){
     if(this.isCaptcha){
       let url:any=this.environment.getRequiredApi("get_authenticated_captcha");
       this.loaderService.loadShow();
       this.requestPayload=Object();
       this.requestPayload.url=url;
       this.requestPayload.params=Object();
       this.requestPayload.params['captchaCode']=this.loginForm.value['captchaCode'];
       this.requestPayload.params['captcha']=this.loginForm.value['captcha'];
       this.authenticationService.verifyCaptcha(this.requestPayload).subscribe(res=>{
         if(res!=null && res.status==0){
           this.getOtp();
         }else{
           this.toastrService.error(res.message);
           this.loaderService.loadHide();
         }
       })
     }else{
       this.getOtp();
     }
   }
   getOtp(){
       let url:any=this.environment.getRequiredApi("get_authenticated_captcha");
       this.loaderService.loadShow();
       this.requestPayload=Object();
       this.requestPayload.url=url;
       this.requestPayload.params=Object();
       this.requestPayload.params['logonId']=this.loginForm.value['email'];
       this.authenticationService.getSecureOTP(this.requestPayload).subscribe(res=>{
         if(res!=null){
           if(res.status==0){
             if(res.hasOwnProperty('data') && Object.keys(res.data).length>0){
               if(res.data.hasOwnProperty('isOtpEnabled')){
                 if(res.data.isOtpEnabled){
                   this.mobileNum=res.data.mNum;
                   this.showOTPBlock=true;
                   this.loginBlock=false;
                   this.otpMsg='Please enter the OTP sent to registered mobile number '+this.mobileNum;
                 }else{
                   this.submitLoginForm();
                 }
               }else{
                this.submitLoginForm();
               }
             }
           }else{
             this.toastrService.warning(res.message);
           }
           this.loaderService.loadHide();
         }
       });
   }
   submitOTP(otp){
     let url:any=this.environment.getRequiredApi("get_authenticated_captcha");
     this.loaderService.loadShow();
     this.requestPayload=Object();
     this.requestPayload.url=url;
     this.requestPayload.params=Object();
     this.requestPayload.params['logonId']=this.loginForm.value['email'];
     this.requestPayload.params['accessParam']=otp;
     this.authenticationService.getVerifyOTP(this.requestPayload).subscribe(res=>{
       if(res!=null){
         if(res.status==0){
           this.submitLoginForm();
         }else{
           this.toastrService.warning(res.message);
         }
       }
     });
   }

  ngOnDestroy(){
    document.body.classList.remove('overflow-hidden');
    document.body.classList.remove('login-footer');
  }

  resetPassword(key:any){
    let url = this.environment.getRequiredApi('reset_pwd_resetKey').replace("{resetKey}", this.tokenReceivedThroughEmail);
          this.requestPayload.url = url;
          this.requestPayload.type = "GET";
          this.commonService.getData(this.requestPayload).subscribe((response) => {
            if (response.hasOwnProperty('data')) {
              let data = response.data;
              if (!(data.keyStatus == 'Active')) {
                if (key == 'ky') {
                  const path: string = 'login';
                  this.router.navigate([path]);
                }
              } else {
                this.userId = data.userId;
              }
            } else {
              this.toastrService.error(response.message);
              const path: string = 'login';
              this.router.navigate([path]);
            }
          });
  }

  submitLoginForm(forceLogin?){
    if(this.loginForm.valid){
      this.cleanForm(this.loginForm);
      this.loaderService.loadShow();
      let username:any=this.loginForm.value['email'];
      let password:any = this.loginForm.value['password'];
      let loginFormUrl:any=this.environment.getRequiredApi("oauth_Token").replace('/v1','');
      this.requestPayload.url=loginFormUrl;
      this.requestPayload.params=Object();
      this.requestPayload.params['username']=username;
      this.requestPayload.params['password']=password;
      this.requestPayload.params['userTypeId']=this.loginForm.value['userTypeId'];
      this.authenticationService.login(this.requestPayload,forceLogin).subscribe(res=>{
          this.localService.setJsonValue('logonId',username);
          this.localService.setJsonValue('userTypeId',this.loginForm.value['userTypeId']);
          var expire = new Date();
          var time = new Date(expire.getTime() + (res.expires_in)*1000);
          this.cookieService.set('token',res.access_token,time, '','', false,"Strict");
          localStorage.setItem('token',res.access_token);
          this.localService.setJsonValue('expireTime',res.expires_in);
          this.cookieService.set('refreshToken',res.refresh_token);
          if(sessionStorage.getItem('previousUrl')!=undefined && sessionStorage.getItem('previousUrl')!=null){
            let previousUrl:any = sessionStorage.getItem('previousUrl');
            sessionStorage.setItem('navigateUrl',previousUrl);
          }
          this.router.navigate(['/landingpage']);
      },(err)=>{
        console.log(err);
        if(err!='You have logged in on another window or device, confirm to logout all active sessions and continue to login.'){
          this.errorMsg=err;
          $("#toast-error").attr('style','opacity:1');
          setTimeout(()=>{
            $("#toast-error").removeAttr('style');
          },6000);
        }else{
          this.openDailog(err);
        }
      });
    }
  }
  dismissToast(){
    $("#toast-error").removeAttr('style');
  }
  login(){
    if(this.loginBlock){
      this.verifyCaptcha();
    }else{
      this.loginBlock=true;
      if(this.resetBlock){
        this.resetBlock = false;
        this.router.navigate(['/']);
      }
      this.formTitle="Sign In"
    }
  }
  forgotPassword(){
    if(!this.loginBlock){
      this.submitForgotPassword();
    }else{
      this.loginBlock=false;
      this.formTitle="Reset Password"
    }
  }
  /* forget password */
  submitForgotPassword(){
    if(this.fPasswordForm.valid){
      this.cleanForm(this.fPasswordForm);
      this.loaderService.loadShow();
      let payload:any=Object();
      payload['action']='FORGOTPWD';
      payload['logonId']=this.fPasswordForm.value.userId;
      payload["emailId"]=this.fPasswordForm.value.email,
      payload['userIds']=[];
      if(this.fPasswordForm.value.email.length ==0 ){
        this.responsesMessage = this.loginFormValidations.email_required;
            setTimeout(() => {
               this.responsesMessage='';
               }, 2000);
               this.loaderService.loadHide();
       }else{
        let url=this.environment.getRequiredApi("fwd_password");
        this.requestPayload.url=url;
        this.requestPayload.body=payload;
        this.authenticationService.forgotPwd(this.requestPayload).subscribe((response) =>{  
            if(response.status==0){ 
             this.toastrService.success(response.message);
             this.fPasswordForm.reset();
             this.loaderService.loadHide();
            }
           else{ 
              this.toastrService.warning(response.message);
              this.loaderService.loadHide();
            }
             },(error) =>{
                 console.log(error); 
                 this.fPasswordForm.reset();  
                 this.toastrService.error('Problem for sending reset link to your email');
                 this.loaderService.loadHide(); 
             });
       }
    }
  }
  showPwdVal(){
    this.showPwd=!this.showPwd;
  }
  showPwdVal1(){
    this.showPwd1=!this.showPwd1;
  }
  patternValidator(regex: RegExp, error: ValidationErrors): any {
    return (control:AbstractControl) : ValidationErrors | null => {
      if (!control.value) {
        return null;
      }
      const valid = regex.test(control.value);
      return valid ? null : error;
    };
  }
  passwordMatchValidator(control: AbstractControl): void | null {
    const password: string = control.get('password')?.value; 
    const confirmPassword: string = control.get('confirmPassword')?.value; 
     if (password !== confirmPassword) {
         control.get('confirmPassword')?.setErrors({ NoPassswordMatch: true });
    }
  }
  submitResetPassword(){
    this.cleanForm(this.resetPasswordForm);
    let payload = {
      "action": "UPDATEPWD",
      "newPwd": this.resetPasswordForm.value.password,
      "resetKey": this.tokenReceivedThroughEmail,
      "userId": this.userId
    }
    if (this.queryParamKeyVal == "orgky") {
      payload = {
        "action": "CREATEPWD",
        "newPwd": this.resetPasswordForm.value.password,
        "resetKey": this.tokenReceivedThroughEmail,
        "userId": this.userId
      }
    }
    let url = this.environment.getRequiredApi('password_reset') + "?";
    let reqmethod = "PUT";
    this.requestPayload.url = url;
    this.requestPayload.type = reqmethod;
    this.requestPayload.body = payload;
    this.commonService.putData(this.requestPayload)
      .subscribe((response) => {
        if (response!=null && response.status==0) {
            this.resetPasswordForm.reset();
            //this.toastrService.success(response.message);
            setTimeout(() => {
              this.cookieService.deleteAll();
              this.localService.clearToken();
              localStorage.clear();
              this.resetPWDSuccessMsg=true;
              this.successMsg = response.message;
            }, 2000);
        } else if(response!=null) {
          this.toastrService.warning(response.message);
        }
      });
  }

  public cleanForm(formGroup: any) {
    Object.keys(formGroup.controls).forEach((key) => {
      if(formGroup.get(key).value!=null && formGroup.get(key).value!=undefined){
        formGroup.get(key).setValue(formGroup.get(key).value.trim())
      }
    });
  }

  get getInfoText(){
    return `Password must contain the following:
    1. One Capital(Uppercase) Letter
    2. One Lowercase Letter
    3. Number
    4. Special character`;
  }

  closeModal(id){
    $("#"+id).modal("hide");
    this.warningText="";
  }
  confirmReset(){
    this.submitLoginForm(true);
  }
  openDailog(err){
    const dailogRef = this.dailog.open(WarningDailogComponent,
      {width:'400px',data:{title:'Session Warning',message:err,confirmText:'Confirm',cancelText:'Cancel',callBack:this.confirmReset.bind(this)},disableClose:true});
    dailogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
    });
  }
  navigateSelfRegistration(){
    this.router.navigate(['/selfRegistration']);
  }
  
}

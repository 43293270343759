import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { LoaderState } from 'src/app/models/loader-state';
import { LoaderService } from './loader.service';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss']
})
export class LoaderComponent implements OnInit {

  show = false;
  loadingShow = false;
  loadingText = "Loading...";
  private subscription: Subscription = new Subscription();
  private loadsubscription: Subscription = new Subscription();
  constructor(private loaderService: LoaderService) { }

  ngOnInit() {
    this.subscription = this.loaderService.loaderState
            .subscribe((state: LoaderState) => {
                this.show = state.show;
            });
    this.loadsubscription = this.loaderService.loaderState1
            .subscribe((state: LoaderState) => {
                this.loadingShow = state.lodingShow;
                this.loadingText = state.loadingText;
            });
  }
  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.loadsubscription.unsubscribe();
}

}

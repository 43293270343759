<div class="app-body mt-0">
    <div class="toast toast-error-block" id="toast-error" role="alert" aria-live="polite" aria-atomic="true" data-animation="true" data-delay="5000">
        <button type="button" class="ml-2 mb-1 close" (click)="dismissToast()" data-dismiss="toast" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
        <div class="toast-body">
          <i class="fa fa-exclamation-circle circle-icon mr-2"></i> {{errorMsg}}
        </div>
    </div>
    <!-- <div class="success-message" *ngIf="resetPWDSuccessMsg">
        {{successMsg}} 
          please click here to <a href="/" class="login-link">Login</a>
    </div> -->
    <div class="col-md-12 p-0 login-main-block" [ngClass]="{'center-div':resetPWDSuccessMsg}">
        <div class="login-block" *ngIf="!resetPWDSuccessMsg">
            <div class="left-panel">
                <div class="logo-header">
                    <img src="./assets/images/logo.png" />
                </div>
                <div class="logo-content-img">
                    <img src="./assets/images/login.png" />
                </div>
            </div>
            <div class="right-panel">
                <div class="form-title">{{formTitle}}</div>
                <div class="login-main-form" [ngClass]="{'center-form-block':url=='' || (!loginBlock && !resetBlock) || resetBlock}">
                    <ng-container *ngIf="loginBlock">
                        <form [formGroup]="loginForm">
                            <div class="form-container">
                                <div class="form-field">
                                    <i class="fa fa-user-icon form-icon"></i>
                                    <select class="form-control form-input" formControlName="userTypeId">
                                        <option value="">User Type</option>
                                        <option value="2">Customer</option>
                                        <option value="1">Employee</option>
                                        <option value="3">Agency</option>
                                    </select>
                                </div>
                                <div class="mat-error">
                                    <ng-container *ngIf="loginForm.controls['userTypeId'].touched && 
                                                    loginForm.controls['userTypeId'].invalid && loginForm.controls['userTypeId'].dirty">
                                                    <small>UserType is required</small>
                                    </ng-container>
                                </div>
                                <div class="form-field">
                                    <i class="fa fa-envelope-icon form-icon"></i>      
                                    <input type="text" formControlName="email" class="form-control form-input-1" placeholder="Username" name="email" id="userid" />
                                </div>
                                <div class="mat-error">
                                    <ng-container *ngIf="loginForm.controls['email'].touched && 
                                                 loginForm.controls['email'].invalid && loginForm.controls['email'].dirty">
                                                  <small>{{errorValidationMsgs.email_required}}</small>
                                    </ng-container>
                                </div>
                                <div class="form-field">
                                    <i class="fa fa-lock-icon form-icon"></i>
                                    <input [type]="showPwd ? 'text' : 'password'" formControlName="password" id="login-password" placeholder="Password" class="form-control form-input-1" name="password"/>
                                    <i class="fa eye-icon" (click)="showPwdVal()" [ngClass]="{'fa-eye-slash':!showPwd,'fa-eye':showPwd}"></i> 
                                </div>
                                <div class="mat-error">
                                    <ng-container
                                    *ngIf="loginForm.controls['password'].touched && 
                                                 loginForm.controls['password'].invalid && loginForm.controls['password'].dirty">
                                                 <small>{{errorValidationMsgs.password_required}}</small>
                                  </ng-container>
                                </div>
                            </div>
                            <div class="captch-field" *ngIf="url!=''">
                                <div class="captcha-label">Enter the captcha shown below:</div>
                                <div class="captcha-block">
                                      <div class="pull-left image-block">
                                        <img [src]="url" class="captcha-image" alt="verify captcha"/>
                                        <div class="refresh-icon" (click)="checkCaptcha()"><i class="fa fa-refresh fa-refresh-icon"></i></div>
                                      </div>
                                      <div class="pull-left code-input">
                                        <input type="text" class="form-control captcha-input" formControlName="captcha" placeholder="Captcha"/>
                                        <div class="mat-error" style="height:20px;">
                                          <ng-container
                                          *ngIf="loginForm.controls['captcha'].touched && 
                                                       loginForm.controls['captcha'].invalid && loginForm.controls['captcha'].dirty">
                                                       <small>Please enter captcha</small>
                                        </ng-container>
                                      </div>  
                                      </div>
                                </div>
                            </div>
                            <div class="form-footer">
                                <button class="btn btn-primary" type="submit" *ngIf="!resetBlock" [disabled]="loginBlock && !loginForm.valid" [ngClass]="{'active-btn':loginBlock}" (click)="login()">Login</button>
                                <div class="register-buttons">
                                    <button class="btn btn-primary register-btn" (click)="navigateSelfRegistration()" type="submit">Self Register</button>
                                    <button class="btn btn-transparent forgot-btn" type="submit"
                                    *ngIf="!resetBlock" [disabled]="!loginBlock && !fPasswordForm.valid" 
                                    [ngClass]="{'active-btn':(!loginBlock && !resetBlock)}" (click)="forgotPassword()">Forgot Password</button>
                                </div>
                            </div>
                        </form>
                    </ng-container>
                    <ng-container *ngIf="!loginBlock && !resetBlock">
                        <form [formGroup]="fPasswordForm">
                            <div class="form-container">
                                <div class="form-field">
                                    <i class="fa fa-user-icon form-icon"></i>
                                    <input type="text" formControlName="userId" class="form-control form-input-1" placeholder="Username" name="userId" id="fuserid" />
                                </div>
                                <div class="mat-error" style="height:20px;">
                                    <ng-container *ngIf="fPasswordForm.controls['userId'].touched && 
                                    fPasswordForm.controls['userId'].invalid && fPasswordForm.controls['userId'].dirty">
                                                  <small>{{errorValidationMsgs.email_required}}</small>
                                    </ng-container>
                                </div>
                                <div class="form-field">
                                    <i class="fa fa-envelope-icon form-icon"></i> 
                                    <input type="text" formControlName="email" class="form-control form-input-1" placeholder="Email" name="email" id="email" />
                                </div>
                                <div class="mat-error" style="height:20px;">
                                    <span
                                      *ngIf="fPasswordForm.controls['email'].touched && fPasswordForm.controls['email'].invalid && fPasswordForm.controls['email'].dirty"
                                      class="error"> <small class="text-danger"
                                      *ngIf="fPasswordForm.controls['email'].hasError('required')">
                                        {{loginFormValidations.email_user_required}} </small> <small class="text-danger"
                                      *ngIf="fPasswordForm.controls['email'].hasError('pattern')">
                                        {{loginFormValidations.email_validation}} </small>
                                    </span>
                                </div>
                            </div>
                            <div class="form-footer">
                                <button class="btn btn-primary" type="submit" *ngIf="!resetBlock" [disabled]="!loginBlock && !fPasswordForm.valid" [ngClass]="{'active-btn':(!loginBlock && !resetBlock)}" (click)="forgotPassword()">Reset Password</button>
                                <div class="register-buttons">
                                    <!-- <button class="btn btn-primary register-btn" type="submit">Self Register</button> -->
                                    <button class="btn btn-transparent forgot-btn" style="margin: auto;"[disabled]="loginBlock && !loginForm.valid" [ngClass]="{'active-btn':loginBlock}" (click)="login()" type="submit">Login</button>
                                </div>
                            </div>
                        </form>
                    </ng-container>
                    <ng-container *ngIf="resetBlock">
                        <form [formGroup]="resetPasswordForm">
                            <div class="form-container">
                                <div class="form-field">
                                    <i class="fa fa-lock-icon form-icon"></i>
                                    <input [type]="showPwd ? 'text' : 'password'" #pwd formControlName="password" id="login-password" placeholder="New Password" class="form-control form-input-1" name="password"/>
                                    <i class="fa eye-icon" (click)="showPwdVal()" [ngClass]="{'fa-eye-slash':!showPwd,'fa-eye':showPwd}"></i> 
                                </div>
                                <div class="mat-error" style="height:20px;">
                                    <ng-container
                                        *ngIf="resetPasswordForm.controls['password'].touched && 
                                        resetPasswordForm.controls['password'].invalid && resetPasswordForm.controls['password'].dirty && pwd.value=='';else numberval">
                                        <small class="text-danger">Enter New Password</small>
                                    </ng-container>
                                    <ng-template #numberval>
                                        <ng-container *ngIf="resetPasswordForm.controls['password'].hasError('hasNumber')||resetPasswordForm.controls['password'].hasError('hasSpecialCharacters')||resetPasswordForm.controls['password'].hasError('hasCapitalCase');else minlengtval">
                                            <small class="text-danger">Password must contain atleast one upper case letter and one digit and one special character</small>
                                        </ng-container>
                                        <ng-template #minlengtval>
                                            <ng-container *ngIf="resetPasswordForm.controls['password'].hasError('minlength');">
                                                <small class="text-danger">Password must have atleast 8 characters length</small>
                                            </ng-container>                                            
                                        </ng-template>
                                    </ng-template>
                                </div>
                                <div class="form-field">
                                    <i class="fa fa-lock-icon form-icon"></i>
                                    <input [type]="showPwd1 ? 'text' : 'password'" #cnfm formControlName="confirmPassword" id="confirm-password" placeholder="Confirm Password" class="form-control form-input-1" name="password"/>
                                    <i class="fa eye-icon" (click)="showPwdVal1()" [ngClass]="{'fa-eye-slash':!showPwd1,'fa-eye':showPwd1}"></i> 
                                </div>
                                <div class="mat-error" style="height:20px;">
                                    <ng-container
                                        *ngIf="resetPasswordForm.controls['confirmPassword'].touched && cnfm.value=='' &&
                                    resetPasswordForm.controls['confirmPassword'].invalid && resetPasswordForm.controls['confirmPassword'].dirty">
                                    <small class="text-danger">Enter Confirm Password</small>
                                    </ng-container>
                                    <ng-container
                                        *ngIf="cnfm.value!='' && resetPasswordForm.controls['confirmPassword'].hasError('NoPassswordMatch')">
                                        <small class="text-danger">Password do not match with confirm password</small>
                                    </ng-container>
                                </div>
                            </div>
                            <div class="form-footer">
                                <button class="btn btn-primary" type="submit" *ngIf="resetBlock" [disabled]="!resetPasswordForm.valid" [ngClass]="{'active-btn':(resetBlock)}" (click)="submitResetPassword()">Confirm Password</button>
                                <div class="register-buttons">
                                    <!-- <button class="btn btn-primary register-btn" type="submit">Self Register</button> -->
                                    <button class="btn btn-transparent forgot-btn" style="margin: auto;" [disabled]="loginBlock && !loginForm.valid" [ngClass]="{'active-btn':loginBlock}" (click)="login()" type="submit">Login</button>
                                </div>
                            </div>
                        </form>
                    </ng-container>
                </div>
            </div>
        </div>
        <div class="success-message" *ngIf="resetPWDSuccessMsg">
            {{successMsg}} 
              please click here to <a href="/sakshieclassified" class="login-link">Login</a>
        </div>
    </div>
</div>
<app-footer></app-footer>
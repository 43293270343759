<section class="card" id="main-content">
    <div class="landing-body" *ngIf="classifiedsList != undefined">
        <ng-container *ngFor="let list of classifiedsList; let i=index;" >
            <div class="widget-item">
                <div class="widget-header">
                    <!-- <div class="widget-head-item">
                        <img src="./assets/images/u3.png" class="main-logo" />
                        <img src="./assets/images/u26.svg" class="news-logo" />
                    </div> -->
                    <div class="widget-title">{{list.type}}</div>
                   
                </div>
                <!-- <div class="widget-body" [innerHTML]="list.description">
                </div> -->
                <div class="widget-head-item">
                    <div *ngIf="list.id === 1">
                    <img src="../../../assets/images/regularclassified.png" class="news-logo" />
                </div>
                <div *ngIf="list.id === 3">
                    <img src="../../../assets/images/display ad image.png" class="news-logo" />
                </div>
                </div>
                <div class="widget-footer">
                    <button mat-raised-button color="primary" (click)="bookRegularClassified(list)" class="footer-primary-btn">Book {{list.type}}</button>
                </div>
            </div>
        </ng-container>
    </div>
        <div class="landing-body" *ngIf="classifiedsList === undefined">
        <ng-container  >
            <div class="widget-item">
                <div class="widget-header">
                    <!-- <div class="widget-head-item">
                        <img src="./assets/images/u3.png" class="main-logo" />
                        <img src="./assets/images/u26.svg" class="news-logo" />
                    </div> -->
                    <!-- <div class="widget-title">{{list.type}}</div> -->
                   
                </div>
                <!-- <div class="widget-body" [innerHTML]="list.description">
                </div> -->
                <div class="widget-head-item">

                    <h3 style="font-size: inherit;
                    position: absolute;
                    top: 161px;
                    left: 29px;
                    color: grey;">No ad types have been assigned , once assigned by the admin will be noticed to you.</h3>
                    <!-- <div *ngIf="list.id === 1">
                    <img src="../../../assets/images/regularclassified.png" class="news-logo" />
                </div>
                <div *ngIf="list.id === 3">
                    <img src="../../../assets/images/display ad image.png" class="news-logo" />
                </div> -->
                </div>
                <div class="widget-footer">
                    <!-- <button mat-raised-button color="primary" (click)="bookRegularClassified(list)" class="footer-primary-btn">Book {{list.type}}</button> -->
                </div>
            </div>
        </ng-container>
    </div>

</section>

<div id="mailContent" class="modal" role="dialog" data-backdrop="static" data-keyboard="false" style="margin-top: 155px;">
    <div class="modal-dialog modal-l">
        <div class="modal-content" >
            <div class="modal-header">
                <h4 class="modal-title">Successfully Updated</h4>
                <button type="button" class="close" (click)="closeModal('mailContent')" data-dismiss="modal">&times;</button>
            </div>
            <div class="modal-body">
                <div class="warning-block">
                    <i class="fa fa-check-circle success-icon"></i>
                  </div>
              <p>Your details has been successfully updated! Your account is under review, and you will be notified once it's approved.</p>
            </div>
            <div class="modal-footer">
                <div class="pull-left" style="position: relative;left: 200px;">
                    <button  mat-raised-button color="primary" (click)="closeModal('mailContent')">Ok</button>
                </div>
            </div>
        </div>
    </div>
</div>
import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-rms-payment-success',
  templateUrl: './rms-payment-success.component.html',
  styleUrls: ['./rms-payment-success.component.scss']
})
export class RmsPaymentSuccessComponent {
  adId:any='';
  transactionId='';
  orderId='';
  amount='';
  authStatus='';
  paymentStatus='';
  transactionDate='';
  flag='';
  message:any;
  class:any;
  constructor(private router:Router,private route: ActivatedRoute){
    this.adId = this.route.snapshot.queryParams['adId'];
    this.transactionId = this.route.snapshot.queryParams['transactionId'];
    this.orderId = this.route.snapshot.queryParams['orderId'];
    this.amount = this.route.snapshot.queryParams['amount'];
    this.authStatus = this.route.snapshot.queryParams['authStatus'];
    this.paymentStatus = this.route.snapshot.queryParams['paymentStatus'];
    this.transactionDate = this.route.snapshot.queryParams['transactionDate'];
    this.flag = this.route.snapshot.queryParams['flag'];

    if(this.flag == 'true'){
      this.message = "Your order was successfully placed with Id " + this.adId +".";
      this.class = "success-msg";
    }else{
      this.message = "There was an error placing your order with Id " + this.adId +".";
      this.class = "error-msg";
    }
  
    console.log('Received paymentId:', this.adId);
    }
}

import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit{
  isLogin:boolean = true;
  constructor(private router:Router){

  }
  ngOnInit(){
    if(this.router.url!='login' && this.router.url!='/' 
    && this.router.url!='/resetpassword' && this.router.url!='/selfRegistration'){
      this.isLogin=false;
    }
  }
}

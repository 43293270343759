import { Component, EventEmitter, Input, Output, Renderer2 } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { RequestPayload } from 'src/app/models/request-payload';
import { ApiConfigurationService } from 'src/app/services/api-configuration.service';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { CommonService } from 'src/app/services/common.service';
import { LocalService } from 'src/app/services/local.service';

@Component({
  selector: 'app-side-nav-list',
  templateUrl: './side-nav-list.component.html',
  styleUrls: ['./side-nav-list.component.scss']
})
export class SideNavListComponent {
  @Input()
  menuBarRes:any;
  @Input()
  userRes:any;
  enteredButton = false;
  isMatMenuOpen = false;
  isMatMenu2Open = false;
  prevButtonTrigger;
  requestPayload:RequestPayload=Object();
  @Input()
  isLanded:boolean=false;
  cartCount:any=0;
  @Output() SideNavToggle = new EventEmitter(); 
  private subscription: Subscription = new Subscription();
  constructor(private ren: Renderer2,private environment:ApiConfigurationService,private authenticationService:AuthenticationService,private authentication:AuthenticationService,
    private router:Router,private cookieService:CookieService,private toastrService: ToastrService, private localService:LocalService,
    private activeRoute:ActivatedRoute,
    private commonService:CommonService){
      this.subscription = this.commonService.loadCartCount
            .subscribe((state: any) => {
                this.cartCount = state;
            });
  }
  menuenter() {
    this.isMatMenuOpen = true;
    if (this.isMatMenu2Open) {
      this.isMatMenu2Open = false;
    }
  }

  menuLeave(trigger, button) {
    setTimeout(() => {
      if (!this.isMatMenu2Open && !this.enteredButton) {
        this.isMatMenuOpen = false;
        trigger.closeMenu();
        if(button['_elementRef']!=undefined){
          this.ren.removeClass(button['_elementRef'].nativeElement, 'cdk-focused');
          this.ren.removeClass(button['_elementRef'].nativeElement, 'cdk-program-focused');
        }
      } else {
        this.isMatMenuOpen = false;
      }
    }, 80)
  }

  menu2enter() {
    this.isMatMenu2Open = true;
  }

  menu2Leave(trigger1, trigger2, button) {
    setTimeout(() => {
      if (this.isMatMenu2Open) {
        trigger1.closeMenu();
        this.isMatMenuOpen = false;
        this.isMatMenu2Open = false;
        this.enteredButton = false;
        if(button['_elementRef']!=undefined){
          this.ren.removeClass(button['_elementRef'].nativeElement, 'cdk-focused');
          this.ren.removeClass(button['_elementRef'].nativeElement, 'cdk-program-focused');
        }
      } else {
        this.isMatMenu2Open = false;
        trigger2.closeMenu();
      }
    }, 100)
  }

  buttonEnter(trigger) {
    setTimeout(() => {
      if(this.prevButtonTrigger && this.prevButtonTrigger != trigger){
        this.prevButtonTrigger.closeMenu();
        this.prevButtonTrigger = trigger;
        this.isMatMenuOpen = false;
        this.isMatMenu2Open = false;
        trigger.openMenu();
        if(trigger.menu.items.first!=undefined && trigger.menu.items.first['_elementRef']!=undefined){
          this.ren.removeClass(trigger.menu.items.first['_elementRef'].nativeElement, 'cdk-focused');
          this.ren.removeClass(trigger.menu.items.first['_elementRef'].nativeElement, 'cdk-program-focused');
        }
      }
      else if (!this.isMatMenuOpen) {
        this.enteredButton = true;
        this.prevButtonTrigger = trigger
        trigger.openMenu();
        if(trigger.menu.items.first!=undefined && trigger.menu.items.first['_elementRef']!=undefined){
          this.ren.removeClass(trigger.menu.items.first['_elementRef'].nativeElement, 'cdk-focused');
          this.ren.removeClass(trigger.menu.items.first['_elementRef'].nativeElement, 'cdk-program-focused');
        }
      }
      else {
        this.enteredButton = true;
        this.prevButtonTrigger = trigger
      }
    })
  }

  buttonLeave(trigger, button) {
    setTimeout(() => {
      if (this.enteredButton && !this.isMatMenuOpen) {
        trigger.closeMenu();
        if(button['_elementRef']!=undefined){
          this.ren.removeClass(button['_elementRef'].nativeElement, 'cdk-focused');
          this.ren.removeClass(button['_elementRef'].nativeElement, 'cdk-program-focused');
        }
      } if (!this.isMatMenuOpen) {
        trigger.closeMenu();
        if(button['_elementRef']!=undefined){
          this.ren.removeClass(button['_elementRef'].nativeElement, 'cdk-focused');
          this.ren.removeClass(button['_elementRef'].nativeElement, 'cdk-program-focused');
        }
      } else {
        this.enteredButton = false;
      }
    }, 100)
  }
  logout(){
    let logoutFormUrl:any=this.environment.getRequiredApi("user_logout").replace('/v1','');
    this.requestPayload.url=logoutFormUrl;
    this.requestPayload.params=Object();
    this.authenticationService.logout(this.requestPayload).subscribe(res=>{
      this.cookieService.deleteAll();
      this.localService.clearToken();
      localStorage.clear();
      sessionStorage.clear();
      sessionStorage.setItem('logout','0');
      this.router.navigate(['/']);
    });
  }
  setAccessObject(objectId,list){
    this.localService.setJsonValue('accessObjectId',objectId);
    this.localService.setJsonValue('permissionsObj',JSON.stringify(list));
    this.authentication.selectAccessObjList(list);
    this.closeNavBar();
  }
  setActive(list){
    let active:boolean=false;
    if(list.navLink!=undefined){
      let link:any=list.navLink.split("/")[1];
      if(this.router.url.includes(link)){
        active=true;
      }
    }
    return active; 
  }
  viewCheckout(){
    this.router.navigate(['classified/checkout']);
  }
  ngOnDestroy() {
    this.subscription.unsubscribe();
}
closeNavBar(){
  this.SideNavToggle.emit()
}
}

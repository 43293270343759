<!-- <div class="card-header">
    <span class="card-title">Success</span>
    <span class="pull-right"></span>
</div> -->
<section class="card" id="main-content">
  <div class="card-block">
      <div class="col-md-12">
          <div class="success-container">
              <!-- <h1 class="success-msg" *ngIf="flag == true">Your order was successfully placed with Id {{adId}}.</h1>
              <h1 class="error-msg" *ngIf="flag == 'false'">There was an error placing your order with Id {{adId}}.</h1> -->
              <h1 class={{class}}>{{message}}</h1>
          </div>
          <div class="table-container">
          <table class="center-table">
              <tr>
                <th>Payment Mode</th>
                <td>Online Payment</td>
              </tr>
              <tr>
                <th>Transaction Number</th>
                <td>{{transactionId}}</td>
              </tr>
              <tr>
                <th>Order Id</th>
                <td>{{orderId}}</td>
              </tr>
              <tr>
                <th>Amount</th>
                <td>{{amount}}</td>
              </tr>
              <tr>
                  <th>Status Of Transaction</th>
                  <td>{{authStatus}}</td>
                </tr>
                <tr>
                  <th>Status Description</th>
                  <td>{{paymentStatus}}</td>
                </tr>
                <tr>
                  <th>Transaction Date</th>
                  <td>{{transactionDate}}</td>
                </tr>
            </table>
            </div>
          <!-- <div class="border-line"></div> -->
          <!-- <div class="payment-group">
              <button mat-raised-button color="primary"  (click)="bookClassified()"
                  class="primary-action-button">
                  <img src="./assets/images/u97.png" class="button-icon-img" /> 
                  Continue to Book Another Classified
              </button>
          </div> -->
      </div>
  </div>
</section>

import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { ToastrService } from 'ngx-toastr';
import { Subject, takeUntil } from 'rxjs';
import { RequestPayload } from 'src/app/models/request-payload';
import { ApiConfigurationService } from 'src/app/services/api-configuration.service';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { CommonService } from 'src/app/services/common.service';
import { GlobalMessagesService } from 'src/app/services/global-messages.service';
import { LocalService } from 'src/app/services/local.service';

@Component({
  selector: 'app-landing-page',
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.scss']
})
export class LandingPageComponent {
  role:any;
  private unsubscribe: Subject<void> = new Subject<void>();
  orgsList:any=[];
  requestPayload:RequestPayload=Object();
  orgId: any;
  userId: any;
  orgName: any;
  createdBy: any;
  classifiedsList:any=[];
  constructor(private authenticationService:AuthenticationService, private router:Router,private localService:LocalService, private cookieService:CookieService,
    private environment:ApiConfigurationService,private msgProperty:GlobalMessagesService,
    private commonService:CommonService, private toastr:ToastrService) {
      this.role=this.localService.getJsonValue("role");
      console.log(this.role);
   }

  ngOnInit(): void {
    document.body.classList.add('landing-page');
    this.localService.setJsonValue('accessObjects',"");
    this.authenticationService.gdDetails.pipe(takeUntil(this.unsubscribe)).pipe(takeUntil(this.unsubscribe)).subscribe((res:any)=>{
      if(res==null || (res!=null && res.length==0)){
        this.role=this.localService.getJsonValue("role");
      }else{
        this.role=res.roleName;
        if(this.role =='APPROVER'){
          this.router.navigate(['classified/myprintAds']);
        }
      }
      if(res!=null && res.roleName!=undefined){
        if(this.router.isActive('/landingpage',true)){
            let accessObject:any=res.accessObjs.filter((map)=>{ return map.navLink==this.msgProperty.rolesHomepage[res.roleName]});
            if(accessObject!=null && accessObject.length>0){
              this.localService.setJsonValue('accessObjectId',accessObject[0].parentObjId);
              this.localService.setJsonValue('permissionsObj',JSON.stringify(accessObject[0]));
            }else{
              for(let list of res.accessObjs){
                if(list.childObjs){
                  let childObject:any=list.childObjs.filter((map)=>{ return map.navLink==this.msgProperty.rolesHomepage[res.roleName]});
                  if(childObject!=null && childObject.length>0){
                    this.localService.setJsonValue('accessObjectId',childObject[0].objId);
                    this.localService.setJsonValue('permissionsObj',JSON.stringify(childObject[0]));
                  }
                }
              }
            }
        }
      }
    });
    this.authenticationService.pltDetails.pipe(takeUntil(this.unsubscribe)).pipe(takeUntil(this.unsubscribe)).subscribe((res:any)=>{
      if(res!=null && res.hasOwnProperty('orgs')){
          let list:any=res.orgs[0];
          this.localService.setJsonValue('orgId',list.orgId);
          this.localService.setJsonValue("bpType",list.businessPartners[0].bpType);
          let bpId:any=list.businessPartners[0].bpId;
          this.localService.setJsonValue('bpId',bpId);
          this.localService.setJsonValue('orgLength',res.orgs.length);
          this.authenticationService.setCurrentOrgId(bpId);
      }
    });
    this.getClassifieds();
  }

  ngOnDestroy() {
    document.body.classList.remove('landing-page');
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }
  getClassifieds(){
    this.requestPayload.url = this.environment.getRequiredApi("user_classifieds");
    this.commonService.getData(this.requestPayload).subscribe(res=>{
      if(res!=null){
        if(res.status==0){
          this.classifiedsList = res.data;
        }else{
          this.toastr.warning(res.message);
        }
      }
    })
  }
  bookRegularClassified(list){
    this.localService.setJsonValue('classifiedType',list.id);
    this.router.navigate(['classified/bookClassified']);
  }
}

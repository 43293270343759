<footer class="app-footer" >
    <div [ngClass]="{'content-text':isLogin , 'content-text-1':!isLogin}">
        COPYRIGHT © 2024 SAKSHI. ALL RIGHTS RESERVED.
    </div>
    <div class="footer-menu" *ngIf="!isLogin">
        <ul class="footer-menu-list">
            <li><a class="menu-item" href="https://www.sakshi.com/termsofusage" target="_blank">Terms</a></li>
            <!-- <li><a class="menu-item" href="javascript:void(0)">Privacy</a></li>
            <li><a class="menu-item" href="javascript:void(0)">Tariff</a></li> -->
            <li><a class="menu-item" href="https://www.sakshi.com/contact-us" target="_blank">Contact us</a></li>
        </ul>
    </div>
</footer>
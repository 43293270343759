import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl, AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { ToastrService } from 'ngx-toastr';
import { ApiConfigurationService } from 'src/app/services/api-configuration.service';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { GlobalMessagesService } from 'src/app/services/global-messages.service';
import { LoaderService } from '../loader/loader.service';
import { DomSanitizer } from '@angular/platform-browser';
import { LocalService } from 'src/app/services/local.service';
import { TranslateService } from '@ngx-translate/core';
import { CommonService } from 'src/app/services/common.service';
import { MatDialog } from '@angular/material/dialog';
import { RequestPayload } from 'src/app/models/request-payload';
import { NameWhiteSpace } from 'src/app/services/nameWhiteSpace.validator';
import { ClassifiedService } from 'src/app/services/classified.service';

declare var $;
@Component({
  selector: 'app-self-registration',
  templateUrl: './self-registration.component.html',
  styleUrls: ['./self-registration.component.scss']
})
export class SelfRegistrationComponent implements OnInit{
  registationForm!:FormGroup;
  errorValidationMsgs:any;
  requestPayload:RequestPayload=Object();
  readonly:boolean=false;
  url:any="";
  isCaptcha:boolean=false;
  mobileNum: any;
  showOTPBlock: boolean | undefined;
  otpMsg: string | undefined;
  statesList:any=[];
  cityList:any=[];
  filterdCityList:any=[];
  constructor(private formBuilder: FormBuilder, private messageProperty:GlobalMessagesService,
    private environment:ApiConfigurationService,private authenticationService:AuthenticationService,
    private router:Router,private cookieService:CookieService,private toastrService:ToastrService,
    private loaderService:LoaderService,private localService:LocalService,private translate: TranslateService,
    private route: ActivatedRoute,private sanitizer:DomSanitizer, private commonService:CommonService,
    private dailog:MatDialog,private classiFiedService:ClassifiedService) {

      this.registationForm=this.formBuilder.group({
        logonId:['',[Validators.required,NameWhiteSpace.noSpaceAllowed]],
        email: ['',[Validators.required, Validators.pattern('^[_A-Za-z0-9-\\+]+(\\.[_A-Za-z0-9-]+)*@[A-Za-z0-9-]+(\\.[A-Za-z0-9]+)*(\\.[A-Za-z]{2,})$')]],
        mobile:['',[Validators.required,Validators.pattern('^[6789]{1}[0-9]{9}$')]],
        firstName:['',[Validators.required,Validators.maxLength(35),NameWhiteSpace.noSpaceAllowed]],
        middleName:[''],
        // lastName:['',[Validators.required,NameWhiteSpace.noSpaceAllowed]],
        lastName:[''],
        address1:['',[Validators.required,Validators.maxLength(35),NameWhiteSpace.noSpaceAllowed]],
        address2:[''],
        address3:['',],
        houseNo:['',[Validators.required,Validators.maxLength(10),Validators.pattern(/^\d/),NameWhiteSpace.noSpaceAllowed]],
        // officeLocation:['',[Validators.required]],
        // aadharNumber:['',[Validators.required,Validators.pattern('^[0-9]{12}$'),NameWhiteSpace.noSpaceAllowed]],
        aadharNumber:[''],
        pinCode:['',[Validators.required,Validators.maxLength(6),Validators.minLength(6),Validators.pattern("^[0-9]*$")]],
        city:['',[Validators.required]],
        state:['',[Validators.required]],
        panNumber:['',],
        gstNo:[''],
        captcha:[''],
        captchaCode:['']
      });
      document.body.classList.add('login-footer');
    }
  ngOnInit(): void {
    this.registationForm.get('middleName')?.disable();
    // this.registationForm.get('lastName')?.clearValidators();
    this.registationForm.get('lastName')?.disable()
    this.getStates();
    this.checkCaptcha();
  }

  getStates(){
    let url:any = this.environment.getRequiredApi("get_states");
    this.requestPayload.url =url;
    this.commonService.getData(this.requestPayload).subscribe(res=>{
      if(res!=null){
        if(res.status==0){
          if(res.hasOwnProperty('data')){
            this.statesList = res.data;
          }
        }else{
          this.statesList=[];
        }
      }
    })
    this.getCity();
  }
  getCity(){
    let url:any = this.environment.getRequiredApi("get_city");
    this.requestPayload.url =url;
    this.commonService.getData(this.requestPayload).subscribe(res=>{
      if(res!=null){
        if(res.status==0){
          if(res.hasOwnProperty('data')){
            this.cityList = res.data;
          }
        }else{
          this.cityList=[];
        }
      }
    })
    this.getBookingUnits();
  }
  bookingUnitsList:any=[];
  getBookingUnits(){
    let url:any = this.environment.getRequiredApi("get_booking_Units");
    this.requestPayload.url =url;
    this.commonService.getData(this.requestPayload).subscribe(res=>{
      if(res!=null){
        if(res.status==0){
          this.bookingUnitsList=[];
          if(res.hasOwnProperty('data')){
            this.bookingUnitsList = res.data;
          }
        }else{
          this.bookingUnitsList=[]
        }
      }
    })
  }

  // filterCityOnState(stateCode){
  //   this.filterdCityList = [];
  //     this.filterdCityList = this.cityList.filter(map=>{return map.stateCode==stateCode});
  // }
  filterCityOnState(stateCode){
    this.filterdCityList = [];
      this.filterdCityList = this.bookingUnitsList.filter(map=>{return map.stateCode==stateCode});
  }
  selfRegister(){
    if(this.registationForm.valid){
      let payload:any=this.registationForm.value;
      payload['action'] = 'CUSTOMER';
      let url:any = this.environment.getRequiredApi("user_registration");
      this.requestPayload.url = url;
      this.requestPayload.body = payload;
      this.commonService.postData(this.requestPayload).subscribe(res=>{
        if(res!=null){
          if(res.status==0){
            // this.toastrService.success(res.message);
            this.registationForm.reset();
            $("#confirmModal").modal("show");
          }else{
            this.toastrService.warning(res.message);
          }
        }
      });
    }
  }
  closeModal(id){
    $("#"+id).modal('hide');
  }
  navigateLoginPage(){
    this.router.navigate(['/']);
  }
  checkCaptcha(){
    let url:any=this.environment.getRequiredApi("get_authenticated_captcha");
    this.loaderService.loadShow();
    this.requestPayload=Object();
    this.requestPayload.url=url;
    this.authenticationService.getCaptcha(this.requestPayload).subscribe(res=>{
      if(res!=null && res.status==0){
        if(res.hasOwnProperty('data')){
         this.isCaptcha=res.data.isCaptchaEnabled;
         if(this.isCaptcha){
          this.registationForm?.get('captcha')?.setValidators([Validators.required]);
          this.registationForm?.get('captcha')?.updateValueAndValidity();
          this.registationForm.get('captchaCode')?.setValidators([Validators.required]);
          this.registationForm.get('captchaCode')?.setValue(res.data.captchaString);
          this.registationForm.get('captchaCode')?.updateValueAndValidity();
         }else{
           this.registationForm.get('captcha')?.clearValidators();
           this.registationForm.get('captcha')?.updateValueAndValidity();
           this.registationForm.get('captchaCode')?.clearValidators();
           this.registationForm.get('captchaCode')?.updateValueAndValidity();
         }
         let url:any='data:image/jpeg;base64,'+res.data.image;
         this.url=this.sanitizer.bypassSecurityTrustUrl(url);
   
        }
      }
      this.loaderService.loadHide();
    })
   }
   verifyCaptcha(){
     if(this.isCaptcha){
       let url:any=this.environment.getRequiredApi("get_authenticated_captcha");
       this.loaderService.loadShow();
       this.requestPayload=Object();
       this.requestPayload.url=url;
       this.requestPayload.params=Object();
       this.requestPayload.params['captchaCode']=this.registationForm.value['captchaCode'];
       this.requestPayload.params['captcha']=this.registationForm.value['captcha'];
       this.authenticationService.verifyCaptcha(this.requestPayload).subscribe(res=>{
         if(res!=null && res.status==0){
           this.getOtp();
         }else{
           this.toastrService.error(res.message);
           this.loaderService.loadHide();
         }
       })
     }else{
       this.getOtp();
     }
   }
   getOtp(){
       let url:any=this.environment.getRequiredApi("get_authenticated_captcha");
       this.loaderService.loadShow();
       this.requestPayload=Object();
       this.requestPayload.url=url;
       this.requestPayload.params=Object();
       this.requestPayload.params['logonId']=this.registationForm.value['email'];
       this.authenticationService.getSecureOTP(this.requestPayload).subscribe(res=>{
         if(res!=null){
           if(res.status==0){
             if(res.hasOwnProperty('data') && Object.keys(res.data).length>0){
               if(res.data.hasOwnProperty('isOtpEnabled')){
                 if(res.data.isOtpEnabled){
                   this.mobileNum=res.data.mNum;
                   this.showOTPBlock=true;
                   this.otpMsg='Please enter the OTP sent to registered mobile number '+this.mobileNum;
                 }else{
                   this.selfRegister();
                 }
               }else{
                this.selfRegister();
               }
             }
           }else{
             this.toastrService.warning(res.message);
           }
           this.loaderService.loadHide();
         }
       });
   }
   numbersOnly(event):any{
    var charCode = (event.which) ? event.which : event.keyCode;
      if (charCode > 31
        && (charCode < 48 || charCode > 57)) {
        event.preventDefault();
        return false;
   }
  }
}

import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpResponse } from '@angular/common/http';
import { filter, finalize, map, Observable, pipe, tap } from 'rxjs';
import { LoaderService } from './../components/loader/loader.service';
import { CookieService } from 'ngx-cookie-service';
import { ToastrService } from 'ngx-toastr';
import { LocalService } from './local.service';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root'
})
export class HTTPInterceptorService {

  constructor(private loaderService: LoaderService, private cookieService: CookieService, private toaster: ToastrService,
    private localService: LocalService) { }

  private showLoader(): void {
    this.loaderService.loadShow();
  }
  private hideLoader(): void {
    this.loaderService.loadHide();
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // clone request and replace 'http://' with 'https://' at the same time
        // clone request and replace 'http://' with 'https://' at the same time
        if(req.hasOwnProperty('headers')){
          if (!req.headers.has('custom-loader')) {
            this.showLoader();
          } else {
            req.headers['headers'].delete("custom-loader");
          }
        }
        let secureReq: any;
        let url: any = "";
        let head: any;
        url = req.url;
        if(!req.url.includes('billdesk') && !req.url.includes('www.google.com')){
          if ((req.url.includes('oauth') && !req.url.includes('revoke-token')) || req.url.includes('unsecure') || req.url.includes('captcha')) {
            head = req.headers.set('X-Requested-With', 'XMLHttpRequest')
          } else {
            let accessObjId: any = (this.localService.getJsonValue("accessObjectId") != null && this.localService.getJsonValue("accessObjectId") != undefined) ? this.localService.getJsonValue("accessObjectId") : environment.non_secure_id;
            let orgId = this.localService.getJsonValue("orgId") != null ? this.localService.getJsonValue("orgId") : "";
            let token = localStorage.getItem("token") != null ? localStorage.getItem("token") : "";
            let opuId = this.localService.getJsonValue("bpId") != null ? this.localService.getJsonValue("bpId") : "";
            let userTypeId:any='';
            if(!window.location.hash.includes('partnerInvite') && !window.location.hash.includes('vendorinvite')){
              userTypeId = this.localService.getJsonValue("userTypeId") != null ? this.localService.getJsonValue("userTypeId") : "";
              head = req.headers.set('X-Requested-With', 'XMLHttpRequest')
                                  .set("org_id", orgId)
                                  .set("opu_id", opuId)
                                  .set("userTypeId", userTypeId.toString())
                                  .set("access_obj_id", accessObjId)
                                  .set("Authorization", "bearer " + token)
            }else{
              accessObjId = environment.non_secure_id
              head = req.headers.set('X-Requested-With', 'XMLHttpRequest')
                                  .set("access_obj_id", accessObjId)
                                  .set("Authorization", "bearer " + token)
            }
      
            // url = req.url + "access_token=" + this.cookieService.get('token') + "&access_obj_id=" + accessObjId 
            //   + "&org_id=" + this.localService.getJsonValue("orgId") + "&bp_id=" + this.localService.getJsonValue("bpId");
          }
        }
        secureReq = req.clone({
          url: url,
          headers: head
        });    

    // send the cloned, "secure" request to the next handler.
    return next.handle(secureReq).pipe(
      tap({
        next:(event)=>{
          if (event instanceof HttpResponse) {
            this.hideLoader();
             return event; 
          }
          return event;
        },
        error: (error) => {
          this.hideLoader();
        }
      })
    );
  }
}

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { CookieService } from 'ngx-cookie-service';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { GdAccessObjects } from 'src/app/models/access-objects';
import { Plants } from 'src/app/models/plants';
import { RequestPayload } from 'src/app/models/request-payload';
import { User } from 'src/app/models/user';
import { ApiConfigurationService } from 'src/app/services/api-configuration.service';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { LocalService } from 'src/app/services/local.service';

declare var $: any;


@Component({
  selector: 'app-default-layout',
  templateUrl: './default-layout.component.html',
  styleUrls: ['./default-layout.component.scss']
})
export class DefaultLayoutComponent implements OnInit {

  public sidebarMinimized = true;
  private changes: MutationObserver | undefined;
  public element: HTMLElement = document.body;
  requestPayload:RequestPayload=Object();
  notificationsCt: any='';
  notificationData: any;
  userList:User=Object();
  plantsList:Plants=Object();
  orgId:any="";
  bpId:any="";
  accessObjectList:GdAccessObjects=Object();
  roleName:any="";
  defaultOrgId:any="";
  invitationId: any="";
  isLanded:boolean=false;
  private unsubscribe: Subject<void> = new Subject<void>();
  constructor(private environment:ApiConfigurationService,private authenticationService:AuthenticationService,
    private router:Router,private toastrService:ToastrService,private localService:LocalService,private translate: TranslateService,
    private cookieService:CookieService, private activeRoute:ActivatedRoute) {
    translate.setDefaultLang('en');
      this.changes = new MutationObserver((mutations) => {
      this.sidebarMinimized = document.body.classList.contains('sidebar-minimized');
    });
    this.changes.observe(<Element>this.element, {
      attributes: true,
      attributeFilter: [ 'class' ]
    });
    this.accessObjectList.accessObjs=[];
   }

  ngOnInit(): void {
    if(this.router.url.includes('landingpage')){
      this.isLanded=true;
    }else{
      this.isLanded=false;
    }
    this.authenticationService.setCurrentOrgId(null);
    this.authenticationService.addAccessObjects(null);
    this.authenticationService.selectAccessObjList(null);
    this.authenticationService.selectParentAccessObjList(null);
    this.authenticationService.getPlantList(null);

    this.authenticationService.addAccessObjects(this.accessObjectList.accessObjs);
    this.authenticationService.currentOrgs.pipe(takeUntil(this.unsubscribe)).pipe(takeUntil(this.unsubscribe)).subscribe(res=>{
      if(res!=null){
        this.getGdAccessObjects();
      }
    });
    let resObj:any=this.localService.getJsonValue("permissionsObj")==undefined ? null : this.localService.getJsonValue("permissionsObj");
    this.authenticationService.selectAccessObjList(JSON.parse(resObj));
    let token = this.cookieService.get('token') != null ? this.cookieService.get('token') : "";
    if(token!=null && token!='' && this.localService.getJsonValue('logonId')!=undefined){
      this.getUserDetails();
    }else{
      if(this.invitationId=='' && token==''){
        this.router.navigate(['/']);
      }
    }
    window.addEventListener('resize', (event)=>{
      // do stuff here
      setTimeout(() => {
        let width: any = $("#main-content").width() +"px"
        $(".outbound-footer").css('width', width);
        $(".full-outbound-footer").css('width', width);
      }, 300);
    });

    $(function () {
      $(document).on('click', function (e) {
        if (!$(e.target).hasClass('settings-icon') && !$(e.target).hasClass('cog-blue')) {
          $(".popup-role-div").hide();
        }
      });
    });
  }
  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  getUserDetails(){
    this.requestPayload=Object();
    let logonId:any=this.localService.getJsonValue('logonId');
    this.requestPayload.url=this.environment.getRequiredApi("user_details")+"?logon_id="+logonId;
    this.authenticationService.getLoginUserDetails(this.requestPayload).subscribe(res=>{
      if(res.status==0){
        this.userList=res['data'];
        console.log(this.userList);
        if(res.data.hasOwnProperty('userId')){
          this.localService.setJsonValue('userId',res.data['userId']);
        }
        if(res.data.hasOwnProperty('mobile')){
          this.localService.setJsonValue('mobile',res.data['mobile']);
        }
        if(res.data.hasOwnProperty('userTypeId')){
          this.localService.setJsonValue('userTypeId',res.data['userTypeId']);
        }
        if(res.data.hasOwnProperty('dealerId')){
          this.localService.setJsonValue('dealerId',res.data['dealerId']);
        }
        if(res.data.hasOwnProperty('bookingOffice')){
          this.localService.setJsonValue('bookingOffice',res.data['bookingOffice']);
        }
        if(res.data.hasOwnProperty('empCode')){
          this.localService.setJsonValue('empCode',res.data['empCode']);
        }
        if(res.data.hasOwnProperty('address')){
          this.localService.setJsonValue('address',res.data['address']);
        }
        if(res.data.hasOwnProperty('firstName')){
          this.localService.setJsonValue('firstName',res.data['firstName']);
        }
        if(res.data.hasOwnProperty('email')){
          this.localService.setJsonValue('email',res.data['email']);
        }
        if(res.data.hasOwnProperty('state')){
          this.localService.setJsonValue('state',res.data['state']);
        }
        if(res.data.hasOwnProperty('houseNo')){
          this.localService.setJsonValue('houseNo',res.data['houseNo']);
        }
        if(res.data.hasOwnProperty('pinCode')){
          this.localService.setJsonValue('pinCode',res.data['pinCode']);
        }
        if(res.data.hasOwnProperty('ifsc')){
          this.localService.setJsonValue('ifsc',res.data['ifsc']);
        }
        if(res.data.hasOwnProperty('branch')){
          this.localService.setJsonValue('branch',res.data['branch']);
        }
        if(res.data.hasOwnProperty('bankName')){
          this.localService.setJsonValue('bankName',res.data['bankName']);
        }
        if(res.data.hasOwnProperty('refrenceRole')){
          this.localService.setJsonValue('refrenceRole',res.data['refrenceRole']);
        }
        if(res.data.hasOwnProperty('attachmentId')){
          this.localService.setJsonValue('attachmentId',res.data['attachmentId']);
        }
        if(res.data.hasOwnProperty('aadharAttachment')){
          this.localService.setJsonValue('aadharAttachment',res.data['aadharAttachment']);
        }
        if(res.data.hasOwnProperty('signature')){
          this.localService.setJsonValue('signature',res.data['signature']);
        }
        if(res.data.hasOwnProperty('otherAttachmentName')){
          this.localService.setJsonValue('otherAttachmentName',res.data['otherAttachmentName']);
        }
        if(res.data.hasOwnProperty('signatureName')){
          this.localService.setJsonValue('signatureName',res.data['signatureName']);
        }
        if(res.data.hasOwnProperty('aadharAttachmentName')){
          this.localService.setJsonValue('aadharAttachmentName',res.data['aadharAttachmentName']);
        }
        if(res.data.hasOwnProperty('termsAndCondName')){
          this.localService.setJsonValue('termsAndCondName',res.data['termsAndCondName']);
        }
        if(res.data.hasOwnProperty('termsAndCondId')){
          this.localService.setJsonValue('termsAndCondId',res.data['termsAndCondId']);
        }
        if(res.data.hasOwnProperty('termsAndConditions')){
          this.localService.setJsonValue('termsAndConditions',res.data['termsAndConditions']);
        }
        if(res.data.hasOwnProperty('agreementForm')){
          this.localService.setJsonValue('agreementForm',res.data['agreementForm']);
        }
        if(res.data.hasOwnProperty('createdByName')){
          this.localService.setJsonValue('createdByName',res.data['createdByName']);
        }
        if(res.data.hasOwnProperty('executiveMobile')){
          this.localService.setJsonValue('executiveMobile',res.data['executiveMobile']);
        }
        if(res.data.hasOwnProperty('executiveEmailId')){
          this.localService.setJsonValue('executiveEmailId',res.data['executiveEmailId']);
        }

        if(res.data.hasOwnProperty('aadharNumber')){
          this.localService.setJsonValue('aadharNumber',res.data['aadharNumber']);
        }
        if(res.data.hasOwnProperty('panNumber')){
          this.localService.setJsonValue('panNumber',res.data['panNumber']);
        }

        if(this.defaultOrgId!='' && (this.localService.getJsonValue("orgId")==null || this.localService.getJsonValue("orgId")=='')){
          this.localService.setJsonValue('orgId',this.defaultOrgId);
        }else{
          let list = JSON.parse(this.localService.getJsonValue("plantRes"));
          if(list!=null && list!=undefined){
            list = list.orgs.filter((map:any) => (map.orgId==this.localService.getJsonValue("orgId") && map.type == this.localService.getJsonValue("userType")));
            list = list.length > 0 ? list[0] : null
            if(list!=null){
              if(this.localService.getJsonValue("bpId")!=undefined){
                let bpId = list.businessPartners.filter((map:any)=> map.bpId == this.localService.getJsonValue("bpId"))
                bpId = bpId[0].bpId;
                let bpLegalName = bpId[0].bpLegalName;
                this.localService.setJsonValue('bpId',bpId);
                this.localService.setJsonValue('bpLegalName',bpLegalName);
                this.authenticationService.setCurrentOrgId(bpId);
              }
            }
          }
        }
        this.getUserPlants();
      }
    });
  }
  getUserPlants(){
    this.requestPayload=Object();
    this.requestPayload.url=this.environment.getRequiredApi("user_plants").replace("{user_id}",this.userList.userId);
    this.authenticationService.getUserPlants(this.requestPayload).subscribe(res=>{
      if(res.status==0){
        this.plantsList=Object();
        this.plantsList=res['data']; 
        this.authenticationService.getPlantList(this.plantsList);
        if(this.plantsList.hasOwnProperty('orgs') && this.plantsList.orgs.length>0){
          if(this.defaultOrgId==null || this.defaultOrgId==''){
            this.defaultOrgId = this.plantsList.orgs[0].orgId;
            this.localService.setJsonValue('orgId',this.defaultOrgId);
          }
          this.orgId=this.defaultOrgId;
          this.localService.setJsonValue('userId',this.userList.userId);
          this.localService.setJsonValue('plantRes',JSON.stringify(this.plantsList));
          this.localService.setJsonValue('orgLength',this.plantsList.orgs.length);
        }
      }
    });
  }
  getGdAccessObjects(){
    this.requestPayload=Object();
    this.requestPayload.url=this.environment.getRequiredApi("gd_accessobjects");
    this.authenticationService.getAccessObjects(this.requestPayload).subscribe(res=>{
      if(res.status==0){
        this.accessObjectList.accessObjs=[];
        this.accessObjectList=res['data'];
        this.localService.setJsonValue('role',this.accessObjectList.roleName);
        this.localService.setJsonValue('roleName',this.accessObjectList.roleName);
        console.log(this.accessObjectList.roleName);
        this.localService.setJsonValue('roleDesc',this.accessObjectList.roleName);
        this.roleName=this.accessObjectList.roleName;
        this.localService.setJsonValue('accessObjects',JSON.stringify(this.accessObjectList));
        this.authenticationService.addAccessObjects(this.accessObjectList);
      }
    })
  }
  updateOrg(event:any){
    if(event!='' && event!=undefined){
      this.orgId=this.localService.getJsonValue('orgId');
      if(this.plantsList!=null){
        let defaultList:any=this.plantsList.orgs.filter((map)=>{return map.orgId==this.orgId});
        if(defaultList!=null && defaultList.length>0){
            let defaultBpList:any=defaultList[0].businessPartners.filter((map:any)=>{return map.bpId==event});
            if(defaultBpList!=null){
              this.localService.setJsonValue("bpType",defaultBpList[0].bpType);
            }
            this.bpId=event;
            if(this.bpId!=undefined){
              this.localService.setJsonValue('bpId',this.bpId);
            }
        }
        this.getGdAccessObjects();
      } 
    }
  }

}

import { NgModule, isDevMode } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AsyncPipe, CommonModule, CurrencyPipe, DatePipe, HashLocationStrategy, LocationStrategy } from '@angular/common';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ToastrModule } from 'ngx-toastr';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { CookieService } from 'ngx-cookie-service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HeaderComponent } from './layouts/header/header.component';
import { FooterComponent } from './layouts/footer/footer.component';
import { DefaultLayoutComponent } from './containers/default-layout/default-layout.component';
import { LandingPageComponent } from './components/landing-page/landing-page.component';
import { LoginComponent } from './components/login/login.component';
import { LoaderComponent } from './components/loader/loader.component';
import { HTTPInterceptorService } from './services/httpinterceptor.service';
import { HTTPErrorInterceptorService } from './services/httperror-interceptor.service';
import {MatMenuModule} from '@angular/material/menu';
import {MatButtonModule} from '@angular/material/button';
import { SharedModule } from './shared/shared.module';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatIconModule} from '@angular/material/icon';

import { HorizontalMenuBarComponent } from './horizontal-menu-bar/horizontal-menu-bar.component';
import { SelfRegistrationComponent } from './components/self-registration/self-registration.component';
import { SideNavListComponent } from './components/side-nav-list/side-nav-list.component';
import { RmsPaymentCheckoutComponent } from './components/classified/rms-payment-checkout/rms-payment-checkout.component';
import { SspSelfRegistrationComponent } from './components/self-registration/ssp-self-registration/ssp-self-registration.component';
import { SignaturePadModule } from 'angular2-signaturepad';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http,"assets/i18n/",".json");
}

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    DefaultLayoutComponent,
    LandingPageComponent,
    LoginComponent,
    LoaderComponent,
    HorizontalMenuBarComponent,
    SelfRegistrationComponent,
    SideNavListComponent,
    RmsPaymentCheckoutComponent,
    SspSelfRegistrationComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: !isDevMode(),
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    }),
    BrowserAnimationsModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    BsDropdownModule.forRoot(),
    ToastrModule.forRoot({
      closeButton:true
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    MatMenuModule,
    MatButtonModule,
    MatSidenavModule,
    MatIconModule,
    SharedModule,
    SignaturePadModule
  ],
  exports:[
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    MatMenuModule,
    MatButtonModule
  ],
  providers: [
    // {
    //   provide: LocationStrategy,
    //   useClass: HashLocationStrategy, 
    // },
DatePipe,
CurrencyPipe,
CookieService,
{ provide: HTTP_INTERCEPTORS, useClass: HTTPInterceptorService, multi: true },
{ provide:HTTP_INTERCEPTORS, useClass:HTTPErrorInterceptorService, multi: true },
AsyncPipe],
  bootstrap: [AppComponent]
})
export class AppModule { }

import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit{
  isLogin:boolean = true;
  @Input() ssoFlag:any;
  locSoFlag1:any;
  logoutSso :any;
  constructor(private router:Router){
    localStorage.setItem("ssoFlag",this.ssoFlag)
    let ssoFlagValue = localStorage.getItem("ssoFlag");
    
    if(localStorage.getItem("ssoFlag")==='undefined'){
      this.locSoFlag1 = sessionStorage.getItem("ssoFlag")=='true'
    }else{
      this.locSoFlag1 =localStorage.getItem("ssoFlag")=='true';
    }
    this.logoutSso = localStorage.getItem("ssoFlag")=='true';
    if(this.ssoFlag !== undefined)
      sessionStorage.setItem("ssoFlag",JSON.stringify(this.ssoFlag));
    

  }
  ngOnInit(){
    if(this.router.url!='login' && this.router.url!='/' 
    && this.router.url!='/resetpassword' && this.router.url!='/selfRegistration'){
      this.isLogin=false;
      
    
      
    }
    //localStorage.setItem("ssoFlag",this.ssoFlag)
    
    
    //this.locSoFlag1 = (Boolean)(localStorage.getItem("ssoFlag1"))
  }
}

import { Injectable } from '@angular/core';
import * as Stomp from 'stompjs';
import * as SockJs from 'sockjs-client';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class WebSocketService {

  constructor() { }
    connect() {
      let socketUrl:any=environment.protocol+"://"+environment.ip_address;
      let socket = new SockJs(socketUrl+`/process/socket`);

      let stompClient = Stomp.over(socket);

      return stompClient;
  }
}

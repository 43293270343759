import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { RequestPayload } from 'src/app/models/request-payload';
import { ApiConfigurationService } from 'src/app/services/api-configuration.service';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { CommonService } from 'src/app/services/common.service';
import { LocalService } from 'src/app/services/local.service';
import { WebSocketService } from 'src/app/services/web-socket.service';


declare var $:any;

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  @Input()
  userRes:any;
  @Input()
  plantsRes:any;
  @Input()
  role:any;
  @Input()
  menuBarRes:any;
  @Output()
  updateOrg = new EventEmitter();
  @Output() SideNavToggle = new EventEmitter();  
  requestPayload:RequestPayload=Object();
  token:any="";
  refreshInterval:any;
  bpId:any;
  orgId:any="";
  plantsDetails:any=[];
  bpLegalName: any="";
  helpText: any;
  roleShortId: string='';
  notificationCount:number = 0;
  menuCounts:any=null;
  notificationList: any = [];
  NotifTitle: string='';
  notesCount: number = 0;
  noteslist: any=[];
  orgName:any="";
  orgsList:any=[];
  viewType: any;
  isLanded:boolean=false;
  bpList:any=[];
  private unsubscribe: Subject<void> = new Subject<void>();
  roleDesc:any="";
  orgLength:any=0;
  unReadNotifications:any=[];
  readNotifications:any=[];
  constructor(private environment:ApiConfigurationService,private authenticationService:AuthenticationService,private authentication:AuthenticationService,
    private router:Router,private cookieService:CookieService,private toastrService: ToastrService, private localService:LocalService,
    private activeRoute:ActivatedRoute,private webSocketService:WebSocketService,
    private commonService:CommonService) {
      let userId:any = this.localService.getJsonValue("userId");
      let stompClient = this.webSocketService.connect();
      stompClient.connect({}, (frame:any) => {
        stompClient.subscribe('/eclassified/notification/'+userId, (notifications:any) => {
            this.notificationCount = JSON.parse(notifications.body);
            this.commonService.updateCartCount(this.notificationCount);
        })
      });
     }

  ngOnInit(): void {
    /*Start Full screen hide*/
    document.addEventListener("keydown", (e) => {
      if (e.keyCode == 115) {
          this.toggleFullScreen();
      }
    }, false);
  /*End Full screen hide*/

  this.token=this.cookieService.get("token");
  let expireTime:any = this.localService.getJsonValue("expireTime");
  expireTime=(expireTime*1000)-500;
  this.refreshInterval = setInterval(()=>{
    console.log("interval start---->");
      if(this.router.url!="/"){
        this.refreshToken();
      }else{
        clearInterval(this.refreshInterval);
      }
  },expireTime);
  this.authentication.currentOrgs.pipe(takeUntil(this.unsubscribe)).pipe(takeUntil(this.unsubscribe)).subscribe(res=>{
    this.orgId=this.localService.getJsonValue("orgId");
      this.bpId=this.localService.getJsonValue("bpId");
      this.plantsRes=JSON.parse(this.localService.getJsonValue("plantRes"));
      this.orgLength=this.localService.getJsonValue('orgLength');
      if(this.orgLength==1){
        $("#switchorg").attr('style','display:none');
      }else{
        $("#switchorg").removeAttr('style');
      }
      if(this.plantsRes!=undefined){
        let list:any=this.plantsRes.orgs.filter((map:any)=>{return (map.orgId==this.orgId)});
        if(list!=null && list.length>0){
          if(list[0].businessPartners.length>0){
            this.bpList=list[0].businessPartners;
            let items:any=list[0].businessPartners.filter((map:any)=>{return map.bpId==this.bpId});
            if(items!=null && items.length!=0){
              this.bpLegalName=items[0].bpLegalName;
              this.localService.setJsonValue('bpLegalName',this.bpLegalName);
            }
          }
        }
      }
      if(this.router.url.includes('landingpage')){
        this.isLanded=true;
      }else{
        this.isLanded=false;
      }
      // this.getBellCounts();
  });
  }
  getBellCounts(){
    let url:any="";
    console.log(this.localService.getJsonValue("roleName"));
    if("SSP_USER" == this.localService.getJsonValue("roleName")){
      url = this.environment.getRequiredApi("display_get_cart_count");
    }else{
      url = this.environment.getRequiredApi("get_cart_count");
    }
    this.requestPayload=Object();
    this.requestPayload.url=url;
    this.commonService.getData(this.requestPayload).subscribe(res=>{
      if(res!=null && res.status==0){
        this.notificationCount = res.data;
        this.commonService.updateCartCount(res.data);
      }
    });
  }
  ngDoCheck(){
    if(this.router.url.includes('landingpage')){
      this.isLanded=true;
    }else{
      this.isLanded=false;
    }
  }
  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }
  ngOnChanges(){
    if(this.plantsRes!=null && Object.keys(this.plantsRes).length>0){
      this.orgsList=this.plantsRes.orgs;
      if(this.orgsList!=undefined){
      let list:any=this.plantsRes.orgs.filter((map:any)=>{return map.orgId==this.orgId});
        if(list!=null && list.length>0){
          this.orgName=list[0].orgName;
            if(list[0].businessPartners.length>0){
              this.bpList=list[0].businessPartners;
              let items:any=list[0].businessPartners.filter((map:any)=>{return map.bpId==this.bpId});
              if(items!=null && items.length!=0){
                this.bpLegalName=items[0].bpLegalName;
                this.localService.setJsonValue('bpLegalName',this.bpLegalName);
              }
            }
        }
      }
      this.orgId=this.localService.getJsonValue("orgId");
      this.role=this.localService.getJsonValue("role");
      this.roleDesc=this.localService.getJsonValue("roleDesc");
    }
  }
  logout(){
    let logoutFormUrl:any=this.environment.getRequiredApi("user_logout").replace('/v1','');
    this.requestPayload.url=logoutFormUrl;
    this.requestPayload.params=Object();
    this.authenticationService.logout(this.requestPayload).subscribe(res=>{
      this.cookieService.deleteAll();
      this.localService.clearToken();
      localStorage.clear();
      sessionStorage.clear();
      sessionStorage.setItem('logout','0');
      this.router.navigate(['/']);
    });
  }
  refreshToken(){
    if(this.cookieService.get("token")==''){
      console.log('expired');
      if(localStorage.getItem('token')==undefined || localStorage.getItem('token')==null){
        this.router.navigate(['/']);
      }else{
        this.getRefreshToken();
        if(this.router.url=='/'){
          clearInterval(this.refreshInterval);
        }
      }
    }
  }
  getRefreshToken(){
    let loginFormUrl:any=this.environment.getRequiredApi("oauth_Token").replace('/v1','');
    this.requestPayload.url=loginFormUrl;
    this.authenticationService.refreshToken(this.requestPayload).subscribe(res=>{
      if(res.refresh_token==null){
        this.cookieService.deleteAll();
        this.router.navigate(['/']);
      }else{
        var expire = new Date();
        var time = new Date(expire.getTime() + (res.expires_in)*1000);
        this.cookieService.set('token',res.access_token,time);
        this.cookieService.set('refreshToken',res.refresh_token);
      }
  });
  }
toggleFullScreen() {
    let elem:any = document.body;
    if (elem['mozRequestFullScreen']) {
        elem['mozRequestFullScreen']();
    } else if (elem.webkitRequestFullScreen) {
        elem.webkitRequestFullScreen();
    }
  }
  toggleSideBar(brType:any){
    if(brType=='md'){
      if(document.body.classList.contains('sidebar-lg-show')){
        document.body.classList.remove('sidebar-lg-show');
      }else{
        document.body.classList.add('sidebar-lg-show');
      }
    }else{
      if(document.body.classList.contains('sidebar-show')){
        document.body.classList.remove('sidebar-show');
      }else{
        document.body.classList.add('sidebar-show');
      }
    }
    
    setTimeout(()=>{
      $($.fn.dataTable.tables(true)).DataTable()
      .columns.adjust();
      let width:any=$("#main-content").css('width');
      $(".outbound-footer").css('width',width);
      $(".full-outbound-footer").css('width',width);
     },400);
  }
  toggleAsideBar(brType:any){
    if(brType=='md'){
    if(document.body.classList.contains('aside-menu-lg-show')){
      document.body.classList.remove('aside-menu-lg-show');
    }else{
      document.body.classList.add('aside-menu-lg-show');
    }
  }else{
    if(document.body.classList.contains('aside-menu-show')){
      document.body.classList.remove('aside-menu-show');
    }else{
      document.body.classList.add('aside-menu-show');
    }
  }
  setTimeout(()=>{
    $($.fn.dataTable.tables(true)).DataTable()
    .columns.adjust();
    let width:any=$("#main-content").css('width');
    $(".outbound-footer").css('width',width);
    $(".full-outbound-footer").css('width',width);
   },400);
  }
  selectOption(list:any){
      this.bpLegalName=list.bpLegalName;
      this.bpId=list.bpId;
      this.updateOrg.emit(this.bpId);
      this.authenticationService.setCurrentOrgId(this.bpId);
  }
  viewNotifications(){
    this.router.navigate(['notification/viewNotifications']);
  }

  getNotifications(){
    let url:any=this.environment.getRequiredApi("get_notifications");
    this.requestPayload=Object();
    this.requestPayload.url=url;
    let payload:any=Object();
    payload['userId']=this.userRes.userId;
    payload['pageNumber']=1;
    payload['pageSize']=10;
    payload['unReadNotification'] = true;
    this.requestPayload.body=payload;
    this.commonService.postGlobalData(this.requestPayload).subscribe(res=>{
      if(res.status==0 && res.hasOwnProperty('data')){
        this.notificationList=res.data;
      }
    })
  }
  updateNotification(list){
    if(!list.isRead){
      let url:any=this.environment.getRequiredApi("update_notifications");
      this.requestPayload=Object();
      this.requestPayload.url=url+"?notificationId="+list.notificationId;
      let payload:any=Object();
      this.commonService.postData(this.requestPayload).subscribe(res=>{
        if(res.status==0){
          list.isRead=true;
          if(list.notificationGroup!='GENERAL'){
            this.viewDetails(list);
          }
        }else{
          this.toastrService.warning(res.message);
        }
      });
    }else{
      if(list.orgFormId!=null && list.orgFormId!='null'){
        this.viewDetails(list);
      }
    }
  }
  viewDetails(list){

  }
  setAccessObject(objectId,list){
    this.localService.setJsonValue('accessObjectId',objectId);
    this.localService.setJsonValue('permissionsObj',JSON.stringify(list));
    this.authentication.selectAccessObjList(list);
  }
  setActive(list){
    let active:boolean=false;
    if(list.navLink!=undefined){
      let link:any=list.navLink.split("/")[1];
      if(this.router.url.includes(link)){
        active=true;
      }
    }
    return active; 
  }
  toggleDropDown(event,list){
    if(event.target.parentElement.classList.contains("nav-item") && event.target.parentElement.classList.contains("nav-dropdown")){
      if(event.target.parentElement.classList.contains("open")){
        event.target.parentElement.classList.remove("open");
      }else{
        $(".nav-item").removeClass("open");
        event.target.parentElement.classList.add("open");
        this.localService.setJsonValue('launchpadItem',list);
        this.authentication.selectParentAccessObjList(list);
        this.router.navigate([list.navLink]);
      }
     }
  }
  openSidenav(){
    this.SideNavToggle.emit();
  }
}

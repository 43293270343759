<section class="card" id="main-content">
    <div class="landing-body">
        <ng-container *ngFor="let list of classifiedsList; let i=index;">
            <div class="widget-item">
                <div class="widget-header">
                    <div class="widget-head-item">
                        <img src="./assets/images/u3.png" class="main-logo" />
                        <img src="./assets/images/u26.svg" class="news-logo" />
                    </div>
                    <div class="widget-title">{{list.type}}</div>
                </div>
                <div class="widget-body" [innerHTML]="list.description">
                </div>
                <div class="widget-footer">
                    <button mat-raised-button color="primary" (click)="bookRegularClassified(list)" class="footer-primary-btn">Book {{list.type}}</button>
                </div>
            </div>
        </ng-container>
</div>
</section>
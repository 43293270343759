import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { LoaderState } from './../../models/loader-state';

@Injectable({
  providedIn: 'root'
})
export class LoaderService {

  private loaderSubject = new Subject<LoaderState>();
  private loaderSubject1 = new Subject<LoaderState>();
  loaderState = this.loaderSubject.asObservable();
  loaderState1 = this.loaderSubject1.asObservable();
  infoText:any="Loading..."
  constructor() { }

  show() {
    this.loaderSubject.next(<LoaderState>{show: true});
  }
  hide() {
      this.loaderSubject.next(<LoaderState>{show: false});
  }
  loadShow(){
      this.loaderSubject1.next(<LoaderState>{lodingShow: true,loadingText:this.infoText});
  }
  loadHide(){
    this.loaderSubject1.next(<LoaderState>{lodingShow: false,loadingText:this.infoText});
  }
}

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { ToastrService } from 'ngx-toastr';
import { lastValueFrom, Subject, takeUntil } from 'rxjs';
import { RequestPayload } from 'src/app/models/request-payload';
import { ApiConfigurationService } from 'src/app/services/api-configuration.service';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { CommonService } from 'src/app/services/common.service';
import { GlobalMessagesService } from 'src/app/services/global-messages.service';
import { LocalService } from 'src/app/services/local.service';
declare var $;
@Component({
  selector: 'app-landing-page',
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.scss']
})
export class LandingPageComponent {
  role:any;
  private unsubscribe: Subject<void> = new Subject<void>();
  orgsList:any=[];
  requestPayload:RequestPayload=Object();
  orgId: any;
  userId: any;
  orgName: any;
  createdBy: any;
  classifiedsList:any=[];
  constructor(private authenticationService:AuthenticationService, private router:Router,private localService:LocalService, private cookieService:CookieService,
    private environment:ApiConfigurationService,private msgProperty:GlobalMessagesService,
    private commonService:CommonService, private toastr:ToastrService,private activeRoute : ActivatedRoute) {
      this.role=this.localService.getJsonValue("role");
      console.log(this.role);
   }

   async ngOnInit() {
    await this.getUserDetails();
    if(this.userList.roleShortId === "SSP_CREATOR"){
      this.router.navigate(['/admin/createSSP']);
    }
    console.log(this.localService.getJsonValue('isSubmitted'));
    const submit = this.localService.getJsonValue('isSubmitted');
    const userTypeId = this.localService.getJsonValue('userTypeId');
    console.log(userTypeId);
    document.body.classList.add('landing-page');
    this.localService.setJsonValue('accessObjects',"");
     this.activeRoute.queryParams.subscribe(params=>{
          if(params['fromUpdate']!=''&&params['fromUpdate']!=null){
           $("#mailContent").modal("show");
          }
        })
    this.authenticationService.gdDetails.pipe(takeUntil(this.unsubscribe)).pipe(takeUntil(this.unsubscribe)).subscribe((res:any)=>{
      if(res==null || (res!=null && res.length==0)){
        this.role=this.localService.getJsonValue("role");
      }else{
        this.role=res.roleName;
        if(this.role =='APPROVER'){
          this.router.navigate(['classified/myprintAds']);
        }
      }
      if(res!=null && res.roleName!=undefined){
        if(this.router.isActive('/landingpage',true)){
            let accessObject:any=res.accessObjs.filter((map)=>{ return map.navLink==this.msgProperty.rolesHomepage[res.roleName]});
            if(accessObject!=null && accessObject.length>0){
              this.localService.setJsonValue('accessObjectId',accessObject[0].parentObjId);
              this.localService.setJsonValue('permissionsObj',JSON.stringify(accessObject[0]));
              if(!submit && userTypeId === 4){
                this.router.navigate(['/classified/vendor']);
              }
            }else{
              for(let list of res.accessObjs){
                if(list.childObjs){
                  let childObject:any=list.childObjs.filter((map)=>{ return map.navLink==this.msgProperty.rolesHomepage[res.roleName]});
                  if(childObject!=null && childObject.length>0){
                    this.localService.setJsonValue('accessObjectId',childObject[0].objId);
                    this.localService.setJsonValue('permissionsObj',JSON.stringify(childObject[0]));
                    if(!submit && userTypeId === 4){
                      this.router.navigate(['/classified/vendor']);
                    }
                  }
                }
              }
            }
        }
      }
    });
    this.authenticationService.pltDetails.pipe(takeUntil(this.unsubscribe)).pipe(takeUntil(this.unsubscribe)).subscribe((res:any)=>{
      if(res!=null && res.hasOwnProperty('orgs')){
          let list:any=res.orgs[0];
          this.localService.setJsonValue('orgId',list.orgId);
          this.localService.setJsonValue("bpType",list.businessPartners[0].bpType);
          let bpId:any=list.businessPartners[0].bpId;
          this.localService.setJsonValue('bpId',bpId);
          this.localService.setJsonValue('orgLength',res.orgs.length);
          this.authenticationService.setCurrentOrgId(bpId);
      }
    });
    this.getClassifieds();
  }
  userList:any=[];
  // getUserDetails(){
  //   this.requestPayload=Object();
  //   let logonId:any=this.localService.getJsonValue('logonId');
  //   this.requestPayload.url=this.environment.getRequiredApi("user_details")+"?logon_id="+logonId;
  //   this.authenticationService.getLoginUserDetails(this.requestPayload).subscribe(res=>{
  //     if(res.status==0){
  //       this.userList=res['data'];
  //       console.log(this.userList);
  //       if(res.data.hasOwnProperty('userId')){
  //         this.localService.setJsonValue('userId',res.data['userId']);
  //       }
  //       if(res.data.hasOwnProperty('mobile')){
  //         this.localService.setJsonValue('mobile',res.data['mobile']);
  //       }
  //       if(res.data.hasOwnProperty('userTypeId')){
  //         this.localService.setJsonValue('userTypeId',res.data['userTypeId']);
  //       }
  //       if(res.data.hasOwnProperty('dealerId')){
  //         this.localService.setJsonValue('dealerId',res.data['dealerId']);
  //       }
  //       if(res.data.hasOwnProperty('bookingOffice')){
  //         this.localService.setJsonValue('bookingOffice',res.data['bookingOffice']);
  //       }
  //       if(res.data.hasOwnProperty('empCode')){
  //         this.localService.setJsonValue('empCode',res.data['empCode']);
  //       }
  //       if(res.data.hasOwnProperty('address')){
  //         this.localService.setJsonValue('address',res.data['address']);
  //       }
  //       if(res.data.hasOwnProperty('firstName')){
  //         this.localService.setJsonValue('firstName',res.data['firstName']);
  //       }
  //       if(res.data.hasOwnProperty('email')){
  //         this.localService.setJsonValue('email',res.data['email']);
  //       }
  //       if(res.data.hasOwnProperty('state')){
  //         this.localService.setJsonValue('state',res.data['state']);
  //       }
  //       if(res.data.hasOwnProperty('houseNo')){
  //         this.localService.setJsonValue('houseNo',res.data['houseNo']);
  //       }
  //       if(res.data.hasOwnProperty('pinCode')){
  //         this.localService.setJsonValue('pinCode',res.data['pinCode']);
  //       }
  //       if(res.data.hasOwnProperty('ifsc')){
  //         this.localService.setJsonValue('ifsc',res.data['ifsc']);
  //       }
  //       if(res.data.hasOwnProperty('branch')){
  //         this.localService.setJsonValue('branch',res.data['branch']);
  //       }
  //       if(res.data.hasOwnProperty('bankName')){
  //         this.localService.setJsonValue('bankName',res.data['bankName']);
  //       }
  //       if(res.data.hasOwnProperty('refrenceRole')){
  //         this.localService.setJsonValue('refrenceRole',res.data['refrenceRole']);
  //       }
       
  //     }
  //   });
  // }
  async getUserDetails() {
    this.requestPayload = Object();
    let logonId: any = this.localService.getJsonValue('logonId');
    this.requestPayload.url = this.environment.getRequiredApi("user_details") + "?logon_id=" + logonId;

    try {
        const res: any = await lastValueFrom(this.authenticationService.getLoginUserDetails(this.requestPayload));

        if (res.status == 0) {
            this.userList = res['data'];
            console.log(this.userList);

            const keysToStore = ['userId', 'mobile', 'userTypeId', 'dealerId', 'bookingOffice', 'empCode', 'address', 
                                'firstName', 'email', 'state', 'houseNo', 'pinCode', 'ifsc', 'branch', 'bankName', 'refrenceRole','isSubmitted'];

            keysToStore.forEach(key => {
                if (res.data.hasOwnProperty(key)) {
                    this.localService.setJsonValue(key, res.data[key]);
                }
            });
            
        }
    } catch (error) {
        console.error("Error fetching user details:", error);
    }
}
  ngOnDestroy() {
    document.body.classList.remove('landing-page');
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }
  getClassifieds(){
    this.requestPayload.url = this.environment.getRequiredApi("user_classifieds");
    this.commonService.getData(this.requestPayload).subscribe(res=>{
      if(res!=null){
        if(res.status==0){
          this.classifiedsList = res.data;
          console.log(this.classifiedsList);
        }else{
          this.toastr.warning(res.message);
        }
      }
    })
  }
  bookRegularClassified(list){
    console.log(list);
    if(list.id === 1){
      this.localService.setJsonValue('classifiedType',list.id);
      this.router.navigate(['classified/bookClassified']);
    }else{
      this.localService.setJsonValue('classifiedType',list.id);
      this.router.navigate(['classified/displayAd'])
    }   
  }

  closeModal(id){
    $("#"+id).modal('hide');
  }
}

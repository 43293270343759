
<mat-sidenav-container>
<mat-sidenav #sidenav>
    <app-side-nav-list [menuBarRes]="accessObjectList" (SideNavToggle)="sidenav.toggle()" [isLanded]="isLanded" [userRes]="userList"></app-side-nav-list>
</mat-sidenav>
<mat-sidenav-content>
<app-header [userRes]="userList" (SideNavToggle)="sidenav.toggle()" [menuBarRes]="accessObjectList" [plantsRes]="plantsList" [role]="roleName" (updateOrg)="updateOrg($event)"></app-header>
<div class="app-body">
    <main class="main">
        <div class="container-fluid top-container">
            <router-outlet></router-outlet>
        </div>
    </main>
</div>
<app-footer></app-footer>
</mat-sidenav-content>
</mat-sidenav-container>

import { Injectable } from '@angular/core';
import { CreateOrder } from '../models/create-order';
import { environment } from 'src/environments/environment';
import * as CryptoJS from 'crypto-js';
import { CommonService } from './common.service';
import { BillDeskConfigData, BillDeskEnv, FlowConfig, NetBankingData, 
  Preference, ThemeConfig } from '../models/billdesk-config';
import * as moment from 'moment';
import { Router } from '@angular/router';
import { ApiConfigurationService } from './api-configuration.service';
import { RequestPayload } from '../models/request-payload';
import { ToastrService } from 'ngx-toastr';
declare var window:any;
@Injectable({
  providedIn: 'root'
})
export class PaymentService {

  config:BillDeskConfigData;
  requestPayload:RequestPayload=Object();
  billDeskEnvInfo!: BillDeskEnv;
  cartItemData:any=null;
  notificationCount:number = 0;
  constructor(private commonService:CommonService, private router:Router,private apiConfig:ApiConfigurationService,
    private toaster:ToastrService) {
    const pre_ference:Preference={
      payment_categories: ["card", "nb", "upi", "wallets","qr"],
      allowed_bins: ["459150", "525211"]
  }
  const net_banking:NetBankingData={
      showPopularBanks : "Y",
      popularBanks: ["Kotak Bank"," AXIS Bank [Retail]", "HDFC Bank [Retail]"]
  }
  const flow_config:FlowConfig={
    merchantId:'',
    bdOrderId:'',
    authToken:'',
    childWindow:true,
    returnUrl:'',
    retryCount:0,
    crossButtonHandling:'Y'
  }
  const theme_config:ThemeConfig={
    sdkPrimaryColor: "#17479e",
    sdkAccentColor: "#000",
    sdkBackgroundColor: "#fff",
    sdkBannerColor: "#ffc107"
  }
  this.config={
    responseHandler:this.responseHandler,
    // merchantLogo:"http://localhost:4600/assets/images/u3.png",
    merchantLogo:"https://pre-prod-asp.s3.ap-south-1.amazonaws.com/static_assets/u3.png",
    flowConfig:flow_config,
    flowType:"payments",
    themeConfig:theme_config
  }
   }

   async billDeskCreateOrder(cartItemsData){
    this.cartItemData = cartItemsData;
    let res = await this.prepareRequest(cartItemsData)
    if(res.status == 0){
      console.log(res.data);
      console.log("Your code ...");
      let response:any = this.parseJwt(res.data);
      this.config.flowConfig.authToken = response.links[1].headers.authorization;
      this.config.flowConfig.bdOrderId = response.bdorderid;
      this.config.flowConfig.merchantId= response.mercid;
      window.loadBillDeskSdk(this.config);
    }else{
     // this.toaster.warning(res.message);
     let response:any = this.parseJwt(res.message);
      this.toaster.warning(response.message);
    }
  }

  async billDeskCreateOrder1(cartItemsData){
    this.cartItemData = cartItemsData;
    let envConfigData:any = await this.getBilldeskDetails();
    this.billDeskEnvInfo = JSON.parse(atob(envConfigData.data.data[0].config_params));
    const secret_key = this.billDeskEnvInfo.secret_key;
    this.config.flowConfig.merchantId=this.billDeskEnvInfo.merchantId;
    let createOrderPayload = await this.prepareRequest(cartItemsData)
    if(createOrderPayload.status == 0){
    // const createOrderPayload:CreateOrder = this.prepareRequest(cartItemsData)
    // let encToken:any = this.getSignedToken(secret_key,createOrderPayload.data);
    this.createOrderFromBackend(createOrderPayload.data);
    // this.createOrderFromBackend(encToken);
    // this.createOrder(encToken);
    }else{
      this.toaster.warning(createOrderPayload.message);
    }
  }
  createOrderFromBackend(encToken){
  this.requestPayload.url=this.apiConfig.getRequiredApi("get_billdesk_payment_options")+"?encToken="+encToken;
  let payload:any=Object();
   payload['encToken']=encToken;
  this.commonService.postData(this.requestPayload).subscribe(res=>{
    if(res!=null && res.status==0){
      console.log(res.data);
      console.log("Your code ...");
      let response:any = this.parseJwt(res.data);
      this.config.flowConfig.authToken = response.links[1].headers.authorization;
      this.config.flowConfig.bdOrderId = response.bdorderid;
      window.loadBillDeskSdk(this.config);
    }else{
      this.toaster.warning(res.message);
    }
  });
}

async prepareRequest(cartItemsData){
  this.requestPayload.url=this.apiConfig.getRequiredApi("prepare_request");
  let payload:any=Object();
  payload['customerId']=cartItemsData.customerId;
  payload['items']=cartItemsData.items;
  payload['orderDetails']=cartItemsData.orderDetails;
  payload['orderType']=cartItemsData.orderType;
  // payload["mercid"]=this.billDeskEnvInfo.merchantId;
  this.requestPayload.body=payload;
  let data:any = this.commonService.postData(this.requestPayload);
  return await data.toPromise();
}
  prepareRequest1(cartItemsData){
    let moment_:any = moment["default"];
    let date:any=new Date();
    let date1:any=moment_(date).format();
    let num = Math.floor(Math.random() * 90000) + 10000;
    const orderPayload:CreateOrder = {
      mercid:this.billDeskEnvInfo.merchantId,
      orderid:"order"+num,
      amount:cartItemsData.orderDetails.grandTotal,
      order_date:date1,
      currency:356,
      ru:"http://localhost:4600/classified/paymentSuccess",
      additional_info:{
        additional_info1:'test',
        additional_info2:'test1'
      },
      split_payment:[{
          mercid: cartItemsData.orderDetails.paymentChildId,
          amount: cartItemsData.orderDetails.grandTotal
        }],
      itemcode:'DIRECT',
      device:{
        init_channel:'internet',
        ip:'183.82.99.35',
        user_agent:'Mozilla/5.0(WindowsNT10.0;WOW64;rv:51.0)Gecko/20100101Firefox/51.0',
        accept_header:'text/html',
        fingerprintid:'61b12c18b5d0cf901be34a23ca64bb19',
        browser_tz:"-330",
        browser_color_depth:"32",
        browser_java_enabled:"false",
        browser_screen_height:"550",
        browser_screen_width:"657",
        browser_language:"en-US",
        browser_javascript_enabled:"true"
      }
    }
    return orderPayload;
  }
  getSignedToken(key,payload){
    var secret = key;
    let token:any = this.encodeToken(payload);

    var signature:any = CryptoJS.HmacSHA256(token, secret);
    signature = this.base64url(signature);

    var signedToken = token + "." + signature;
    return signedToken;
  }
  encodeToken(payload:any) {
    var header = {
      "alg": "HS256",
      "typ": "JWT",
      "clientid":this.billDeskEnvInfo.billdeskClientId,
      "kid": "HMAC"
    };

    var stringifiedHeader = CryptoJS.enc.Utf8.parse(JSON.stringify(header));
    var encodedHeader = this.base64url(stringifiedHeader);

    var stringifiedData = CryptoJS.enc.Utf8.parse(JSON.stringify(payload));
    var encodedData = this.base64url(stringifiedData);

    var token = encodedHeader + "." + encodedData;
    return token;
  }
  base64url(source: any) {
    let encodedSource = CryptoJS.enc.Base64.stringify(source);

    encodedSource = encodedSource.replace(/=+$/, '');

    encodedSource = encodedSource.replace(/\+/g, '-');
    encodedSource = encodedSource.replace(/\//g, '_');

    return encodedSource;
  }
  parseJwt(token:any) {
    return JSON.parse(atob(token.split(".")[1]));
  }
  createOrder(authToken){
    this.commonService.billDeskCreate(authToken).subscribe(res=>{
      if(res!=null){
        console.log(res);
        console.log("Your code ...");
        let response:any = this.parseJwt(res);
        this.config.flowConfig.authToken = response.links[1].headers.authorization;
        this.config.flowConfig.bdOrderId = response.bdorderid;
        window.loadBillDeskSdk(this.config);
      }
    })
  }
  responseHandler = (txn: { status: any; txnResponse: any; })=> {
    console.log("callback received status:: ",txn.status)
    console.log("callback received response:: ",txn.txnResponse)
    this.updatePaymentDetails(txn);
  }
  async getBilldeskDetails(){
    this.requestPayload.url=this.apiConfig.getRequiredApi("get_masters_filter")+"GDPAYMENTGATEWAYCONFIG";
    let payload:any=Object();
    payload['env']=environment.billdeskEnv;
    this.requestPayload.body=payload;
    let data:any = this.commonService.postData(this.requestPayload);
    return await data.toPromise();
  }
  updatePaymentDetails(txn){
    this.requestPayload.url = this.apiConfig.getRequiredApi("update_payment_tsdata");
    let payload:any=txn.txnResponse;
    payload['secOrderId']=this.cartItemData.orderDetails.orderId;
    this.requestPayload.body=payload;
    if(txn.txnResponse!=undefined){
      this.commonService.postData(this.requestPayload).subscribe(res=>{
        if(res!=null && res.status==0){
          this.getBellCounts();
          if(this.cartItemData.orderType == 1){
            this.router.navigate(['rms/paymentStatus'],{ queryParams: { adId: this.cartItemData.items[0].adId,transactionId : res.data.transactionId,orderId:res.data.orderId,amount:res.data.amount,authStatus:res.data.authStatus,paymentStatus:res.data.transactionErrorDesc,transactionDate:res.data.transactionDate,flag:true } });
          }else{
          this.router.navigate(['classified/paymentSuccess'],{ queryParams: { adId: this.cartItemData.items[0].adId,transactionId : res.data.transactionId,orderId:res.data.orderId,amount:res.data.amount,authStatus:res.data.authStatus,paymentStatus:res.data.transactionErrorDesc,transactionDate:res.data.transactionDate,flag:true } });
          }
        }else{
          if(this.cartItemData.orderType == 1){
            this.router.navigate(['rms/paymentStatus'],{ queryParams: { adId: this.cartItemData.items[0].adId,transactionId : res.data.transactionId,orderId:res.data.orderId,amount:res.data.amount,authStatus:res.data.authStatus,paymentStatus:res.data.transactionErrorDesc,transactionDate:res.data.transactionDate,flag:false } });
          }else{
          this.router.navigate(['classified/paymentSuccess'],{ queryParams: { adId: this.cartItemData.items[0].adId,transactionId : res.data.transactionId,orderId:res.data.orderId,amount:res.data.amount,authStatus:res.data.authStatus,paymentStatus:res.data.transactionErrorDesc,transactionDate:res.data.transactionDate,flag:false } });
          }
          this.toaster.warning(res.message);
        }
      });
    }else{
      this.toaster.warning("Payment cancelled");
    }
  }
  getBellCounts(){
    let url:any=this.apiConfig.getRequiredApi("get_cart_count");
    this.requestPayload=Object();
    this.requestPayload.url=url;
    this.commonService.getData(this.requestPayload).subscribe(res=>{
      if(res!=null && res.status==0){
        this.notificationCount = res.data;
        this.commonService.updateCartCount(res.data);
      }
    });
  }
}

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { GdAccessObjects, ChildObj } from '../models/access-objects';
import { Plants } from '../models/plants';
import { RequestPayload } from '../models/request-payload';
import { ApiConfigurationService } from './api-configuration.service';
import { LocalService } from './local.service';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  private gdAccessObjects:BehaviorSubject<GdAccessObjects>;
  public gdDetails:Observable<GdAccessObjects>;
  private plantList:BehaviorSubject<Plants>;
  public pltDetails:Observable<Plants>;
  public currentOrgId: BehaviorSubject<String>;
  public currentOrgs:Observable<String>;
  public selectAccessObject:BehaviorSubject<ChildObj>;
  public selectObject:Observable<ChildObj>;
  public selectParentAccessObject:BehaviorSubject<ChildObj>;
  public selectParentObject:Observable<ChildObj>;
  public setActiveActionLogObject:BehaviorSubject<any>;
  public getActiveLogObject:Observable<any>;
  constructor(private http: HttpClient, private apiUrlConfigurationService:ApiConfigurationService,
    private cookieService:CookieService,private localService:LocalService) {
      this.gdAccessObjects = new BehaviorSubject<GdAccessObjects>(Object());
      this.gdDetails = this.gdAccessObjects.asObservable();
      this.plantList = new BehaviorSubject<Plants>(Object());
      this.pltDetails = this.plantList.asObservable();
      this.currentOrgId = new BehaviorSubject<String>(this.localService.getJsonValue("orgId"));
      this.currentOrgs = this.currentOrgId.asObservable();
      this.selectAccessObject = new BehaviorSubject<ChildObj>(Object());
      this.selectObject = this.selectAccessObject.asObservable();
      this.selectParentAccessObject = new BehaviorSubject<ChildObj>(Object());
      this.selectParentObject = this.selectParentAccessObject.asObservable();
      this.setActiveActionLogObject = new BehaviorSubject<any>(Object());
      this.getActiveLogObject = this.setActiveActionLogObject.asObservable();
         }

    setCurrentOrgId(response: any){
      this.currentOrgId.next(response);
    }
  
    addAccessObjects(response:any){
      this.gdAccessObjects.next(response);
    }
    selectAccessObjList(response:any){
      this.selectAccessObject.next(response);
    }
    selectParentAccessObjList(response:any){
      this.selectParentAccessObject.next(response);
    }
    getPlantList(response:any){
      this.plantList.next(response);
    }
    setactiveActionLogObjectReq(request:any){
      this.setActiveActionLogObject.next(request);
        }
    login(requestPayload:RequestPayload,forceLogin?){
      let username:any=requestPayload.params['username'];
      let password:any=requestPayload.params['password'];
      let customHeaders:any=null;
      if(forceLogin){
        customHeaders ={
          'userTypeId':requestPayload.params['userTypeId'],
          'Content-Type':  'application/x-www-form-urlencoded; charset=utf-8',
          'Authorization' :'Basic ' + btoa(this.apiUrlConfigurationService.getEnvironment().clientId +':'+this.apiUrlConfigurationService.getEnvironment().clientPassword),
          'forceLogin':'true'
        }
      }else{
        customHeaders ={
          'userTypeId':requestPayload.params['userTypeId'],
          // 'userTypeId':'1',
          'Content-Type':  'application/x-www-form-urlencoded; charset=utf-8',
          'Authorization' :'Basic ' + btoa(this.apiUrlConfigurationService.getEnvironment().clientId +':'+this.apiUrlConfigurationService.getEnvironment().clientPassword),
        }
      }
      const  headers=new HttpHeaders(customHeaders)
      const httpOptions = {
        headers:headers
      }
      let params = new URLSearchParams();   
      params.append('username',username);
      params.append('password',password);    
      params.append('grant_type','password');
      //params.append('client_id',this.apiUrlConfigurationService.getEnvironment().clientId);
      //params.append('client_secret',this.apiUrlConfigurationService.getEnvironment().clientPassword); 
      return this.http.post<any>(requestPayload.url, params.toString(), httpOptions)
        .pipe(map(authToken=>{
          return authToken;
        }));
    }
    logout(requestPayload:RequestPayload){
      const  headers=new HttpHeaders({
        'Content-Type':  'application/json; charset=utf-8',
        'custom-loader': 'false'
      })
      const httpOptions = {
        headers:headers
      }
      requestPayload.url = requestPayload.url;
      return this.http.get<any>(requestPayload.url,httpOptions)
        .pipe(map(res=>{
          return res;
        }));
    }
    forgotPwd(requestPayload:RequestPayload){
      const  headers=new HttpHeaders({
        'Content-Type':  'application/json; charset=utf-8'
      })
      const httpOptions = {
        headers:headers
      }
      let body=requestPayload.body;
      requestPayload.url=requestPayload.url;
      return this.http.post<any>(requestPayload.url,body, httpOptions);
    
    }
    getLoginUserDetails(requestPayload:RequestPayload){
      const  headers=new HttpHeaders({
        'Content-Type':  'application/json; charset=utf-8'
      })
      const httpOptions = {
        headers:headers
      }
      requestPayload.url = requestPayload.url;
      return this.http.get<any>(requestPayload.url,httpOptions)
        .pipe(map(res=>{
          return res;
        }));
    }
    getUserPlants(requestPayload:RequestPayload){
      const  headers=new HttpHeaders({
        'Content-Type':  'application/json; charset=utf-8',
      })
      const httpOptions = {
        headers:headers
      }
      requestPayload.url = requestPayload.url;
      return this.http.get<any>(requestPayload.url,httpOptions)
        .pipe(map(res=>{
          return res;
        }));
    }
    getAccessObjects(requestPayload:RequestPayload){
      const  headers=new HttpHeaders({
        'Content-Type':  'application/json; charset=utf-8'
      })
      const httpOptions = {
        headers:headers
      }
      requestPayload.url = requestPayload.url;
      return this.http.get<any>(requestPayload.url,httpOptions)
        .pipe(map(res=>{
          return res;
        }));
    }
    refreshToken(requestPayload:RequestPayload){
      let userTypeId:any = this.localService.getJsonValue('userTypeId');
      const  headers=new HttpHeaders({
        'userTypeId':userTypeId.toString(),
        'Content-Type':  'application/x-www-form-urlencoded; charset=utf-8',
        'Authorization' :'Basic ' + btoa(this.apiUrlConfigurationService.getEnvironment().clientId +':'+this.apiUrlConfigurationService.getEnvironment().clientPassword)
      })
      const httpOptions = {
        headers:headers
      }
      let params = new URLSearchParams();
      params.append('refresh_token',this.cookieService.get('refreshToken'));    
      params.append('grant_type','refresh_token');
      return this.http.post<any>(requestPayload.url, params.toString(), httpOptions)
        .pipe(map(authToken=>{
          return authToken;
        }));
    }
    getCaptcha(requestPayload:RequestPayload){
      const  headers=new HttpHeaders({
        'viewName':'login',
        'viewType':'CAPTCHA',
        'Content-Type':  'application/x-www-form-urlencoded; charset=utf-8',
      })
      const httpOptions = {
        headers:headers
      }
      return this.http.get<any>(requestPayload.url, httpOptions)
        .pipe(map(authToken=>{
          return authToken;
        }));
    }
    verifyCaptcha(requestPayload:RequestPayload){
      const  headers=new HttpHeaders({
        'viewName':'login',
        'viewType':'CAPTCHA',
        'Content-Type':  'application/x-www-form-urlencoded; charset=utf-8',
        'oCaptcha':requestPayload.params['captchaCode'],
        'iCaptcha':requestPayload.params['captcha']
      })
      const httpOptions = {
        headers:headers
      }
      return this.http.get<any>(requestPayload.url, httpOptions)
        .pipe(map(authToken=>{
          return authToken;
        }));
    }
    getSecureOTP(requestPayload:RequestPayload){
      const  headers=new HttpHeaders({
        'viewName':'login',
        'viewType':'OTP',
        'Content-Type':  'application/json; charset=utf-8',
        'logonId':requestPayload.params['logonId']
      })
      const httpOptions = {
        headers:headers
      }
      return this.http.get<any>(requestPayload.url, httpOptions)
        .pipe(map(authToken=>{
          return authToken;
        }));
    }
    getVerifyOTP(requestPayload:RequestPayload){
      const  headers=new HttpHeaders({
        'viewName':'login',
        'viewType':'OTP',
        'Content-Type':  'application/json; charset=utf-8',
        'logonId':requestPayload.params['logonId'],
        'accessParam':requestPayload.params['accessParam']
      })
      const httpOptions = {
        headers:headers
      }
      return this.http.get<any>(requestPayload.url, httpOptions)
        .pipe(map(authToken=>{
          return authToken;
        }));
    }
}

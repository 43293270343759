<footer style="display: grid;">
    <div *ngIf="(ssoFlag || locSoFlag1)&&isLogin">
        <p class="disclaimer" style="background-color: azure;"><strong>Disclaimer</strong><br>
            <small>Advertisers and users of Jagati Publications Limited’s online application (S.S.P. app.) are advised to conduct due diligence before responding to any advertisement. The newspaper/website assumes no liability for the accuracy, legitimacy,
                or authenticity of the claims made by advertisers. Advertisers are strictly prohibited from collecting money from the public through advertisements, except in cases of the sale of goods or services rendered. The management reserves the
                right to blacklist, suspend, or initiate legal action against any advertiser found violating these terms, including unauthorized monetary collections.</small>
        </p>
    </div>

   <div  [ngClass]="{'app-footer-1':isLogin && ((ssoFlag || locSoFlag1)|| (!ssoFlag && !locSoFlag1))  , 'app-footer':!isLogin }" >
    <div [ngClass]="{'content-text':isLogin && (ssoFlag || locSoFlag1) , 'content-text-1':!isLogin , 'content-text-2':isLogin && (!ssoFlag && !locSoFlag1)}">
        <!-- *ngIf="(ssoFlag && ssoFlag !== 'undefined') || (locSoFlag1&&ssoFlag==='undefined')" -->
        <div *ngIf="ssoFlag || locSoFlag1">
            COPYRIGHT © 2025 SSP.SAKSHI.COM. ALL RIGHTS RESERVED. POWERED BY JAGATI PUBLICATIONS LIMITED.
        </div>
        

        <div *ngIf="!ssoFlag && !locSoFlag1">
            COPYRIGHT © 2024 SAKSHI. ALL RIGHTS RESERVED.
        </div>
        
        
        <!-- <div *ngIf="!ssoFlag">
            COPYRIGHT © 2024 SAKSHI. ALL RIGHTS RESERVED.
        </div> -->
        
    </div>
    
    <div class="footer-menu" *ngIf="!isLogin && (ssoFlag || locSoFlag1)">
        <ul class="footer-menu-list">
            <li><a class="menu-item" href="https://www.sakshi.com/termsofusage" target="_blank">Terms</a></li>
            <!-- <li><a class="menu-item" href="javascript:void(0)">Privacy</a></li>
            <li><a class="menu-item" href="javascript:void(0)">Tariff</a></li> -->
            <li><a class="menu-item" href="https://www.sakshi.com/contact-us" target="_blank">Contact us</a></li>
        </ul>
    </div>

    <div class="footer-menu" *ngIf="!isLogin && (!ssoFlag && !locSoFlag1)">
        <ul class="footer-menu-list-1">
            <li><a class="menu-item" href="https://www.sakshi.com/termsofusage" target="_blank">Terms</a></li>
            <!-- <li><a class="menu-item" href="javascript:void(0)">Privacy</a></li>
            <li><a class="menu-item" href="javascript:void(0)">Tariff</a></li> -->
            <li><a class="menu-item" href="https://www.sakshi.com/contact-us" target="_blank">Contact us</a></li>
        </ul>
    </div>
   </div>
</footer>
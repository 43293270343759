import { AbstractControl, ValidationErrors } from '@angular/forms';  
    
export class NameWhiteSpace {  
    static noSpaceAllowed(control: AbstractControl) : ValidationErrors | null {  
        if(control.value!=null && (typeof control.value === 'string' && control.value.trim().length === 0) ||
        (Array.isArray(control.value) && control.value.length === 0)){  
            return {noSpaceAllowed: true}  
        }  
    
        return null;  
    }  
}

<div [class.hidden]="!show">
    <div class="loader-overlay">
      <div *ngIf="show" class="loader"></div>
    </div>
  </div>
  <div [class.hidden]="!loadingShow">
    <div class="loader-overlay">
        <div *ngIf="loadingShow" class="loader-block">
          <div class="d-flex justify-content-center" id="loading-wheel">
            <img src="assets/images/loader.gif" class="loading-img" />
            <span class="loading-info">
              {{loadingText}}
            </span>
          </div>
        </div>
      </div>
    </div>

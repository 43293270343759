import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { ToastrService } from 'ngx-toastr';
import { RequestPayload } from 'src/app/models/request-payload';
import { ApiConfigurationService } from 'src/app/services/api-configuration.service';
import { CommonService } from 'src/app/services/common.service';
import { LocalService } from 'src/app/services/local.service';
import { PaymentService } from 'src/app/services/payment.service';

@Component({
  selector: 'app-rms-payment-checkout',
  templateUrl: './rms-payment-checkout.component.html',
  styleUrls: ['./rms-payment-checkout.component.scss']
})
export class RmsPaymentCheckoutComponent implements OnInit{
  cartItemsData:any;
  requestPayload:RequestPayload = Object();
  
  constructor(private paymentService:PaymentService,private route: ActivatedRoute,
    private cookieService:CookieService,private localService:LocalService,private toaster:ToastrService,
  private apiConfig:ApiConfigurationService,private commonService:CommonService){
    }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      this.cartItemsData = {
        orderType:1,
        flag:true,
        orderDetails: {
          orderId: params['orderId'],
          paymentChildId: params['paymentChildId'],
          grandTotal: parseFloat(params['amount'])
        },
        items: [
          {
            itemId: params['itemId'],
            adId: params['adId']
          }
        ]
      };
      this.makePayment();
    });
  }
  async makePayment(){
    let res = await this.getpaymentPreviousDetails(this.cartItemsData.orderDetails.orderId);
    if(res.status == 0){
      this.paymentService.billDeskCreateOrder(this.cartItemsData);
    }else{
      this.toaster.warning(res.message);
    }
  }

  async getpaymentPreviousDetails(orderId){
    let url:any =this.apiConfig.getRequiredApi("get_previous_tran_details")+"?orderId="+orderId + "&linkFlag=" +true;
    this.requestPayload.url = url;
    let data:any = this.commonService.getData(this.requestPayload);
    return await data.toPromise();
  }
}

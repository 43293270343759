import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { Router, ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { CookieService } from 'ngx-cookie-service';
import { ToastrService } from 'ngx-toastr';
import { RequestPayload } from 'src/app/models/request-payload';
import { ApiConfigurationService } from 'src/app/services/api-configuration.service';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { ClassifiedService } from 'src/app/services/classified.service';
import { CommonService } from 'src/app/services/common.service';
import { GlobalMessagesService } from 'src/app/services/global-messages.service';
import { LocalService } from 'src/app/services/local.service';
import { NameWhiteSpace } from 'src/app/services/nameWhiteSpace.validator';
import { LoaderService } from '../../loader/loader.service';
import { SignaturePad } from 'angular2-signaturepad';
declare var $;
@Component({
  selector: 'app-ssp-self-registration',
  templateUrl: './ssp-self-registration.component.html',
  styleUrls: ['./ssp-self-registration.component.scss']
})
export class SspSelfRegistrationComponent implements OnInit{
  @ViewChild(SignaturePad) signaturePad!: SignaturePad;
  registationForm!:FormGroup;
  errorValidationMsgs:any;
  requestPayload:RequestPayload=Object();
  readonly:boolean=false;
  url:any="";
  isCaptcha:boolean=false;
  mobileNum: any;
  showOTPBlock: boolean | undefined;
  otpMsg: string | undefined;
  statesList:any=[];
  cityList:any=[];
  filterdCityList:any=[];
  uploadForm:FormGroup;
  constructor(private formBuilder: FormBuilder, private messageProperty:GlobalMessagesService,
    private environment:ApiConfigurationService,private authenticationService:AuthenticationService,
    private router:Router,private cookieService:CookieService,private toastrService:ToastrService,
    private loaderService:LoaderService,private localService:LocalService,private translate: TranslateService,
    private route: ActivatedRoute,private sanitizer:DomSanitizer, private commonService:CommonService,
    private dailog:MatDialog,private classiFiedService:ClassifiedService) {

      this.registationForm=this.formBuilder.group({
        // logonId:['',[Validators.required,NameWhiteSpace.noSpaceAllowed]],
        email: ['',[Validators.required, Validators.pattern('^[_A-Za-z0-9-\\+]+(\\.[_A-Za-z0-9-]+)*@[A-Za-z0-9-]+(\\.[A-Za-z0-9]+)*(\\.[A-Za-z]{2,})$')]],
        mobile:['',[Validators.required,Validators.pattern('^[6789]{1}[0-9]{9}$')]],
        firstName:['',[Validators.required,Validators.maxLength(35),NameWhiteSpace.noSpaceAllowed]],
        middleName:[''],
        // lastName:['',[Validators.required,NameWhiteSpace.noSpaceAllowed]],
        lastName:[''],
        address1:['',[Validators.required,Validators.maxLength(35),NameWhiteSpace.noSpaceAllowed]],
        address2:[''],
        address3:['',],
        houseNo:['',[Validators.required,Validators.maxLength(10),Validators.pattern(/^\d/),NameWhiteSpace.noSpaceAllowed]],
        // officeLocation:['',[Validators.required]],
        // aadharNumber:['',[Validators.required,Validators.pattern('^[0-9]{12}$'),NameWhiteSpace.noSpaceAllowed]],
        aadharNumber:[''],
        pinCode:['',[Validators.required,Validators.maxLength(6),Validators.minLength(6),Validators.pattern("^[0-9]*$")]],
        city:['',[Validators.required]],
        state:['',[Validators.required]],
        panNumber:['',],
        gstNo:[''],
        captcha:[''],
        captchaCode:[''],
        attachmentId:['',[Validators.required]],
        ifsc:['',[Validators.required]],
        branch:['',[Validators.required]],
        bankName:['',[Validators.required]],
        refrenceRole:[''],
        panAttachments:['',[Validators.required]],
        aadharAttachment:['',[Validators.required]],
        signature:['',[Validators.required]],
      });
      this.uploadForm=this.formBuilder.group({
        file:['',[Validators.required]]
      });
      document.body.classList.add('login-footer');
    }
  ngOnInit(): void {
    this.registationForm.get('middleName')?.disable();
    // this.registationForm.get('lastName')?.clearValidators();
    this.registationForm.get('lastName')?.disable()
    this.getStates();
    this.checkCaptcha();
    // this.getRoles();
    this.getUsers();
  }

  roleList:any=[];
  roleId:any=[];
  secondaryRoleList:any=[];
  userList:any = [];
  getRoles(){
    const url = this.environment.getRequiredApi('om_get_org_roles') + "?action=LIST&role_type=ADMIN&";
    this.requestPayload.url = url;
    this.commonService.getData(this.requestPayload)
      .subscribe((response) => {
        if (response['status'] == 0) {
          this.roleList = response['data']['roles'];
          this.roleList = this.roleList.filter((map)=>{return map.roleShortId!='EXTERNAL_USER'});
          this.roleId = this.roleList[0].roleId;
          this.secondaryRoleList = response['data']['roles'];
        }
       
      });
  }

  usersList:any=[];
  getUsers(type?){
      let url: any = this.environment.getRequiredApi('ssp_user_roles')+"/"+this.localService.getJsonValue("orgId") + "?" + "action=ALL";
      if(type!=undefined && type!='ALL' && type!=''){
        url = url+"&type="+type;
      }
      this.requestPayload.url = url;
      this.commonService.getData(this.requestPayload).subscribe((res:any) => {
        if(res.status==0){
          this.userList = [];
          this.userList = res['data'].users;
          this.usersList=this.userList.filter(user => user.roleType === 'ADMIN');
          console.log(this.usersList);
        }else{
          this.userList = [];
          this.toastrService.warning(res['message']);
        }
      });
    }
  getStates(){
    let url:any = this.environment.getRequiredApi("get_states");
    this.requestPayload.url =url;
    this.commonService.getData(this.requestPayload).subscribe(res=>{
      if(res!=null){
        if(res.status==0){
          if(res.hasOwnProperty('data')){
            this.statesList = res.data;
          }
        }else{
          this.statesList=[];
        }
      }
    })
    this.getCity();
  }
  getCity(){
    let url:any = this.environment.getRequiredApi("get_city");
    this.requestPayload.url =url;
    this.commonService.getData(this.requestPayload).subscribe(res=>{
      if(res!=null){
        if(res.status==0){
          if(res.hasOwnProperty('data')){
            this.cityList = res.data;
          }
        }else{
          this.cityList=[];
        }
      }
    })
    this.getBookingUnits();
  }
  bookingUnitsList:any=[];
  getBookingUnits(){
    let url:any = this.environment.getRequiredApi("get_booking_Units");
    this.requestPayload.url =url;
    this.commonService.getData(this.requestPayload).subscribe(res=>{
      if(res!=null){
        if(res.status==0){
          this.bookingUnitsList=[];
          if(res.hasOwnProperty('data')){
            this.bookingUnitsList = res.data;
          }
        }else{
          this.bookingUnitsList=[]
        }
      }
    })
  }

  // filterCityOnState(stateCode){
  //   this.filterdCityList = [];
  //     this.filterdCityList = this.cityList.filter(map=>{return map.stateCode==stateCode});
  // }
  filterCityOnState(stateCode){
    this.filterdCityList = [];
      this.filterdCityList = this.bookingUnitsList.filter(map=>{return map.stateCode==stateCode});
  }
  uploadDoc(action){
    this.action = action;
    $("#attachmentModel").modal("show");
  }
   
  uploadSignature(action){
    this.action = action;
    $("#signModal").modal("show");
  }


  signaturePadOptions: Object = {
    minWidth: 1,
    canvasWidth: 400,
    canvasHeight: 200
  };

  signatureImage: string | null = null;

  openSignatureModal() {
    this.signatureImage = null;
    $("#signModal").modal("show");
    setTimeout(() => {
      this.signaturePad.clear();
    }, 500); // Ensure canvas is reset
  }

  closeSignatureModal() {
    $("#signModal").modal("hide");
    this.clearSignature();
  }

  drawComplete() {
    this.signatureImage = this.signaturePad.toDataURL();
  }

  clearSignature() {
    this.signaturePad.clear();
    this.signatureImage = null;
  }

  generatedFileName: any ;
  confirmSignature() {
    if (this.signaturePad.isEmpty()) {
      alert('Please sign before confirming.');
      return;
    }

    const randomNumber = Math.floor(1000 + Math.random() * 9000); // Random 4-digit number
    this.generatedFileName = `sign_${randomNumber}.png`;
    
    this.signatureImage = this.signaturePad.toDataURL(); // Get base64 PNG data
    this.convertToFile(this.signatureImage); // Convert to File object and attach to form data


    console.log('Signature Saved:', this.generatedFileName, this.signatureImage);
   
  }

  convertToFile(dataUrl: string) {
    fetch(dataUrl)
      .then(res => res.blob()) // Convert Base64 to Blob
      .then(blob => {
        const randomNum = Math.floor(10000 + Math.random() * 90000); // Generate random number
        const file = new File([blob], `sig_${randomNum}.png`, { type: 'image/png' }); // Create File object

        const fileInput = new File([file], file.name, { type: file.type });

        this.uploadForm.patchValue({ file: fileInput }); // Update form
        this.uploadForm.markAsTouched();

        console.log('Signature File:', this.uploadForm.get('file')?.value);
        this.submitDocFile();
        this.closeSignatureModal();
      })
      .catch(err => console.error('Error converting signature:', err));
  }
  attachmentName:any='';
  attachmentUrl:any;
  attachementId:any;
  attachmentIds:any=[];
  otherAttachmentName:any;
  aadharAttachmentName:any;
  panAttachmentName:any;
  otherAttachmentId:any;
  aadharAttachmentId:any;
  panAttachmentId:any;
  signatureName:any;
  signatureId:any;
  submitDocFile(){
    this.attachmentName='';
    const formData = new FormData();
    formData.append("files",this.uploadForm.value['file']);
    this.commonService.uploadSSPDocAttachment(formData).subscribe(res=>{
      if(res!=null && res.status==0){
        this.toastrService.success(res.message);
        if(this.action === 'others'){
          this.registationForm.get('attachmentId')?.setValue(res.data[0].id);
          this.otherAttachmentName = res.data[0].attName;
          this.otherAttachmentId = res.data[0].id;
        }else if(this.action === 'aadhar'){
          this.registationForm.get('aadharAttachment')?.setValue(res.data[0].id);
          this.aadharAttachmentName = res.data[0].attName;
          this.aadharAttachmentId = res.data[0].id;
        }else if(this.action === 'panAttachments'){
          this.registationForm.get('panAttachments')?.setValue(res.data[0].id);
          this.panAttachmentName = res.data[0].attName;
          this.panAttachmentId = res.data[0].id;
        }else if(this.action === 'sign'){
          this.registationForm.get('signature')?.setValue(res.data[0].id);
          this.signatureName = res.data[0].attName;
          this.signatureId = res.data[0].id;
        }
        
        // this.attachmentName = res.data[0].attName;
        // this.attachmentUrl = res.data[0].attatchUrl;
        // this.attachementId = res.data[0].id;
        // $("#file").val('');
        // $("#attachmentModel").modal("hide");
        this.clearSignature();
        this.uploadForm.reset();
      }else if(res!=null){
        this.toastrService.warning(res.message);
      }
    })
  }
  action:any;
  fileUpload(event,action?:any):any{
    let reader = new FileReader();
    let files = event.target.files;
    if(files.length>0){
      for (let i = 0; i < files.length; i++) {
        let object:any = Object();
        let file:any=files[i];
        if (file.type != "") {
          let ext: any = file.name.substring(file.name.lastIndexOf('.') + 1);
          ext = ext.toUpperCase();
          let acceptFiles:any=['JPG','PNG','JPEG'];
          this.action = action;
          if(acceptFiles.indexOf(ext)==-1){
              this.toastrService.error("Invalid Upload File");
              $("#file").val('');
              this.uploadForm.get('file')?.setValue('');
              return false;
          }else{
            if(file.size>5000000){
              this.toastrService.error("File Size maximum allowed 5MB only");
              $("#file").val('');
              this.uploadForm.get('file')?.setValue('')
              return false;
            }
          }
          this.uploadForm.get('file')?.setValue(file);
          this.submitDocFile();
          reader.readAsDataURL(file);
          reader.onload = (_event) => {
            console.log(reader.result);
          }
        }
      }
    }
  } 
  selfRegister(){
    if(this.registationForm.valid){
      let payload:any=this.registationForm.value;
      payload['action'] = 'SSP';
      // payload['attachmentId'] = this.registationForm.get('attachmentId');
      let url:any = this.environment.getRequiredApi("user_registration");
      this.requestPayload.url = url;
      this.requestPayload.body = payload;
      this.commonService.postData(this.requestPayload).subscribe(res=>{
        if(res!=null){
          if(res.status==0){
            // this.toastrService.success(res.message);
            this.registationForm.reset();
            // this.otherAttachmentName = null;
            // this.aadharAttachmentName = null;
            // this.panAttachmentName = null;
            this.signatureName = null;
            $("#file").val('');
            $("#confirmModal").modal("show");
          }else{
            this.toastrService.warning(res.message);
          }
        }
      });
    }
  }
  closeModal(id){
    $("#"+id).modal('hide');
  }
  navigateLoginPage(){
    this.router.navigate(['/']);
  }
  checkCaptcha(){
    let url:any=this.environment.getRequiredApi("get_authenticated_captcha");
    this.loaderService.loadShow();
    this.requestPayload=Object();
    this.requestPayload.url=url;
    this.authenticationService.getCaptcha(this.requestPayload).subscribe(res=>{
      if(res!=null && res.status==0){
        if(res.hasOwnProperty('data')){
         this.isCaptcha=res.data.isCaptchaEnabled;
         if(this.isCaptcha){
          this.registationForm?.get('captcha')?.setValidators([Validators.required]);
          this.registationForm?.get('captcha')?.updateValueAndValidity();
          this.registationForm.get('captchaCode')?.setValidators([Validators.required]);
          this.registationForm.get('captchaCode')?.setValue(res.data.captchaString);
          this.registationForm.get('captchaCode')?.updateValueAndValidity();
         }else{
           this.registationForm.get('captcha')?.clearValidators();
           this.registationForm.get('captcha')?.updateValueAndValidity();
           this.registationForm.get('captchaCode')?.clearValidators();
           this.registationForm.get('captchaCode')?.updateValueAndValidity();
         }
         let url:any='data:image/jpeg;base64,'+res.data.image;
         this.url=this.sanitizer.bypassSecurityTrustUrl(url);
   
        }
      }
      this.loaderService.loadHide();
    })
   }
   verifyCaptcha(){
     if(this.isCaptcha){
       let url:any=this.environment.getRequiredApi("get_authenticated_captcha");
       this.loaderService.loadShow();
       this.requestPayload=Object();
       this.requestPayload.url=url;
       this.requestPayload.params=Object();
       this.requestPayload.params['captchaCode']=this.registationForm.value['captchaCode'];
       this.requestPayload.params['captcha']=this.registationForm.value['captcha'];
       this.authenticationService.verifyCaptcha(this.requestPayload).subscribe(res=>{
         if(res!=null && res.status==0){
           this.getOtp();
         }else{
           this.toastrService.error(res.message);
           this.loaderService.loadHide();
         }
       })
     }else{
       this.getOtp();
     }
   }
   getOtp(){
       let url:any=this.environment.getRequiredApi("get_authenticated_captcha");
       this.loaderService.loadShow();
       this.requestPayload=Object();
       this.requestPayload.url=url;
       this.requestPayload.params=Object();
       this.requestPayload.params['logonId']=this.registationForm.value['email'];
       this.authenticationService.getSecureOTP(this.requestPayload).subscribe(res=>{
         if(res!=null){
           if(res.status==0){
             if(res.hasOwnProperty('data') && Object.keys(res.data).length>0){
               if(res.data.hasOwnProperty('isOtpEnabled')){
                 if(res.data.isOtpEnabled){
                   this.mobileNum=res.data.mNum;
                   this.showOTPBlock=true;
                   this.otpMsg='Please enter the OTP sent to registered mobile number '+this.mobileNum;
                 }else{
                   this.selfRegister();
                 }
               }else{
                this.selfRegister();
               }
             }
           }else{
             this.toastrService.warning(res.message);
           }
           this.loaderService.loadHide();
         }
       });
   }
   numbersOnly(event):any{
    var charCode = (event.which) ? event.which : event.keyCode;
      if (charCode > 31
        && (charCode < 48 || charCode > 57)) {
        event.preventDefault();
        return false;
   }
  }

}

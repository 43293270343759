import { Component, HostListener } from '@angular/core';
import { Router, NavigationStart, NavigationEnd } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { filter } from 'rxjs/operators';
import { LocalService } from './services/local.service';

declare var $;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'SakshiEnews';
  previousUrl:any="";
  currentUrl:any="";
  constructor(private router:Router, private cookieService:CookieService, private localService:LocalService){

  }

  ngOnInit(){
    this.router.events.pipe(
      filter((event) => event instanceof NavigationStart)
      ).subscribe((event:any) => {
        this.previousUrl = this.currentUrl;
        this.currentUrl = event.url;
        if((this.cookieService.get('token')=='' || this.cookieService.get('token') == null || this.cookieService.get('token')==undefined) && sessionStorage.getItem('logout')=='0'){
          this.localService.setJsonValue('previousUrl',this.previousUrl);
          sessionStorage.setItem('previousUrl',this.previousUrl);
        } 

        if(this.currentUrl.includes("rms/checkout")){
          let url = this.currentUrl.split("&");
          if(url!=null){
            let tokenObject:any = url[url.length-2].split("=")[1];
            let access_obj_id = url[url.length-1].split("=")[1];
            if(tokenObject!=null){
              let res:any = JSON.parse(atob(decodeURIComponent((tokenObject))));
              var expire = new Date();
              var time = new Date(expire.getTime() + (res.expires_in)*1000);
              this.cookieService.set('token',res.access_token,time, '','', false,"Strict");
              localStorage.setItem('token',res.access_token);
              this.localService.setJsonValue('expireTime',res.expires_in);
              this.localService.setJsonValue('accessObjectId',atob(access_obj_id));
              this.cookieService.set('refreshToken',res.refresh_token);
              this.localService.setJsonValue("orgId",1000);
              this.localService.setJsonValue('userTypeId',1);
            }
          }
        }
        
      });
  }
}

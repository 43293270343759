import { Injectable } from '@angular/core';
import { RequestPayload } from '../models/request-payload';
import { ApiConfigurationService } from './api-configuration.service';
import { CommonService } from './common.service';

@Injectable({
  providedIn: 'root'
})
export class ClassifiedService {

  requestPayload:RequestPayload = Object();
  constructor(private environment:ApiConfigurationService, private commonService:CommonService) {

  }

  getMasterData(masterType){
    let url:any = this.environment.getRequiredApi("get_masters")+masterType;
    this.requestPayload.url  = url;
    return this.commonService.getData(this.requestPayload);
  }
  addToCartList(payload){
    let url:any = this.environment.getRequiredApi("add_to_cart");
    this.requestPayload.url  = url;
    this.requestPayload.body = payload;
    return this.commonService.postData(this.requestPayload);
  }

  addSSPVendor(payload){
    let url:any = this.environment.getRequiredApi("create_ssp");
    this.requestPayload.url  = url;
    this.requestPayload.body = payload;
    return this.commonService.postData(this.requestPayload);
  }

  // getUsersOnBookingCode(){
  //   let url:any = this.environment.getRequiredApi("get_users_on_booking_code");
  //   this.requestPayload.url  = url;
  //   return this.commonService.getData(this.requestPayload);
  // }


  getRmsApprovers(){
    let url:any = this.environment.getRequiredApi("get_users_on_booking_code");
    this.requestPayload.url  = url;
    return this.commonService.getData(this.requestPayload);
  }

  displayAdaddToCartList(payload){
    let url:any = this.environment.getRequiredApi("display_ad_add_to_cart");
    this.requestPayload.url  = url;
    this.requestPayload.body = payload;
    return this.commonService.postData(this.requestPayload);
  }
  getCartItemList(){
    let url:any = this.environment.getRequiredApi("cart_items_list");
    this.requestPayload.url = url;
    return this.commonService.getData(this.requestPayload);
  }

  getDisplayCartItemList(){
    let url:any = this.environment.getRequiredApi("display_cart_items_list");
    this.requestPayload.url = url;
    return this.commonService.getData(this.requestPayload);
  }
  getCustomerData(mobileNo){
    let url:any = this.environment.getRequiredApi("customer_data")+"?mobileNo="+mobileNo;
    this.requestPayload.url = url;
    return this.commonService.getData(this.requestPayload);
  }

  getDaCustomerData(mobileNo){
    let url:any = this.environment.getRequiredApi("get_da_customer_data")+"?mobileNo="+mobileNo;
    this.requestPayload.url = url;
    return this.commonService.getData(this.requestPayload);
  }
  getClassifiedData(payload){
    let url:any = this.environment.getRequiredApi("classified_dashboard");
    this.requestPayload.url = url;
    this.requestPayload.body = payload;
    return this.commonService.postData(this.requestPayload);
  }

  getDisplayClassifiedData(payload){
    let url:any = this.environment.getRequiredApi("display_classified_dashboard");
    this.requestPayload.url = url;
    this.requestPayload.body = payload;
    return this.commonService.postData(this.requestPayload);
  }
  getClassifiedStatusList(payload){
    let url:any = this.environment.getRequiredApi("classified_download_status_list");
    this.requestPayload.url = url;
    this.requestPayload.body = payload;
    return this.commonService.postData(this.requestPayload);
  }

  getDisplayClassifiedStatusList(payload){
    let url:any = this.environment.getRequiredApi("display_download_status_list");
    this.requestPayload.url = url;
    this.requestPayload.body = payload;
    return this.commonService.postData(this.requestPayload);
  }
  getCounts(payload){
    let url:any = this.environment.getRequiredApi("classified_counts");
    this.requestPayload.url = url;
    this.requestPayload.body = payload;
    return this.commonService.postData(this.requestPayload);
  }

  getDisplayCounts(payload){
    let url:any = this.environment.getRequiredApi("display_classified_counts");
    this.requestPayload.url = url;
    this.requestPayload.body = payload;
    return this.commonService.postData(this.requestPayload);
  }
  getRates(payload){
    let url:any = this.environment.getRequiredApi('classified_rates');
    this.requestPayload.url = url;
    this.requestPayload.body = payload;
    return this.commonService.postGlobalData(this.requestPayload);
  }

  getDisplayRates(payload){
    let url:any = this.environment.getRequiredApi('display_rates');
    this.requestPayload.url = url;
    this.requestPayload.body = payload;
    return this.commonService.postGlobalData(this.requestPayload);
  }
  getTemplates(langCode){
    let url:any = this.environment.getRequiredApi("classified_templates")+"?id="+langCode;
    this.requestPayload.url = url;
    return this.commonService.getData(this.requestPayload);
  }
  getPaymentHistory(){
    let url:any = this.environment.getRequiredApi("payment_history");
    this.requestPayload.url = url;
    return this.commonService.getData(this.requestPayload);
  }

  getDaCategory(){
    let url:any = this.environment.getRequiredApi("get_da-Category");
    this.requestPayload.url = url;
    return this.commonService.getData(this.requestPayload);
  }

  getDaTemplates(){
    let url:any = this.environment.getRequiredApi("get_da_templates");
    this.requestPayload.url = url;
    return this.commonService.getData(this.requestPayload);
  }

  getDaSizes(){
    let url:any = this.environment.getRequiredApi("get_all_da_sizes");
    this.requestPayload.url = url;
    return this.commonService.getData(this.requestPayload);
  }

  getClassifiedItemData(itemId){
    let url:any = this.environment.getRequiredApi("get_cart_item_details");
    this.requestPayload.url = url+"?itemId="+itemId;
    this.requestPayload.body = '';
    return this.commonService.postData(this.requestPayload);
  }

  getDaItemData(itemId){
    let url:any = this.environment.getRequiredApi("edit_da_items");
    this.requestPayload.url = url+"?itemId="+itemId;
    this.requestPayload.body = '';
    return this.commonService.postData(this.requestPayload);
  }

  deleteClassifiedItem(itemId){
    let url:any = this.environment.getRequiredApi("delete_cart_item");
    this.requestPayload.url = url+"?itemId="+itemId;
    this.requestPayload.body = '';
    return this.commonService.postData(this.requestPayload);
  }

  deleteDaItem(itemId){
    let url:any = this.environment.getRequiredApi("da_delete");
    this.requestPayload.url = url+"?itemId="+itemId;
    this.requestPayload.body = '';
    return this.commonService.postData(this.requestPayload);
  }

  approoveItems(payload){
    let url:any = this.environment.getRequiredApi("approove_item");
    this.requestPayload.url = url;
    this.requestPayload.body = payload;
    return this.commonService.postData(this.requestPayload);
  }

  displayApprooveItems(payload){
    let url:any = this.environment.getRequiredApi("display_approove_item");
    this.requestPayload.url = url;
    this.requestPayload.body = payload;
    return this.commonService.postData(this.requestPayload);
  }

  approoveItemsRms(payload){
    // let url:any = this.environment.getRequiredApi("rms_approval_reject");
    let url:any = this.environment.getRequiredApi("update_wf_action");
    this.requestPayload.url = url;
    this.requestPayload.body = payload;
    return this.commonService.postData(this.requestPayload);
  }

  downloadAdsPdf(payload){
    let url:any=this.environment.getRequiredApi("ads_pdf_download");
    this.requestPayload.url = url;
    this.requestPayload.body = payload;
    return this.commonService.postData(this.requestPayload);
  }

  downloadDisplayAdsPdf(payload){
    let url:any=this.environment.getRequiredApi("da_ads_pdf_download");
    this.requestPayload.url = url;
    this.requestPayload.body = payload;
    return this.commonService.postData(this.requestPayload);
  }
  syncToSap(payload){
    let url:any=this.environment.getRequiredApi("sync_to_sap");
    this.requestPayload.url = url;
    this.requestPayload.body = payload;
    return this.commonService.postData(this.requestPayload);
  }
  getPendingClassifiedData(payload){
    let url:any=this.environment.getRequiredApi("Pending_payment_list");
    this.requestPayload.url = url;
    this.requestPayload.body = payload;
    return this.commonService.postData(this.requestPayload);
  }

  getDisplayPendingClassifiedData(payload){
    let url:any=this.environment.getRequiredApi("display_Pending_payment_list");
    this.requestPayload.url = url;
    this.requestPayload.body = payload;
    return this.commonService.postData(this.requestPayload);
  }
  translateData(value, lang){
    let url:any = "https://www.google.com/inputtools/request?text="+value+"&ime="+lang+"&num=5&cp=0&cs=0&ie=utf-8&oe=utf-8&app=jsapi";
    this.requestPayload.url = url;
    return this.commonService.getTranslateData(this.requestPayload);
  }

  getClassifiedExcelStatusList(payload){
    let url:any = this.environment.getRequiredApi("classified_download_excel");
    this.requestPayload.url = url;
    this.requestPayload.body = payload;
    return this.commonService.postData(this.requestPayload);
  }

  getDaVendorCommissionList(payload){
    let url:any = this.environment.getRequiredApi("da_vendor_commission");
    this.requestPayload.url = url;
    this.requestPayload.body = payload;
    return this.commonService.postData(this.requestPayload);
  }

  getPaymentAndRefundDetails(orderId){
    let url:any = this.environment.getRequiredApi("payment_refund_data")+"?orderId="+orderId;
    this.requestPayload.url = url;
    return this.commonService.getData(this.requestPayload);
  }

  getExecutiveSSPVendors(){
    let url:any = this.environment.getRequiredApi("get_executive_users");
    this.requestPayload.url = url;
    return this.commonService.getData(this.requestPayload);
  }

  getRmsInboxData(payload){
    // let url:any = this.environment.getRequiredApi("rms_inbox_data");
    // this.requestPayload.url = url;
    // return this.commonService.getData(this.requestPayload);

    // let url:any = this.environment.getRequiredApi("rms_inbox_data");
    let url:any = this.environment.getRequiredApi("rms_wf_inbox_data");
    this.requestPayload.url = url;
    this.requestPayload.body = payload;
    return this.commonService.postData(this.requestPayload);
  }

  getRmsListView(payload){
    let url:any = this.environment.getRequiredApi("rms_list_view");
    this.requestPayload.url = url;
    this.requestPayload.body = payload;
    return this.commonService.postData(this.requestPayload);
  }
  createRefundDetails(payloadDetails){
    let url:any = this.environment.getRequiredApi("create_refund");
    this.requestPayload.url = url;
    this.requestPayload.body=payloadDetails;
    return this.commonService.postData(this.requestPayload);
  }

  getEncodeString(orderId){
    let url:any = this.environment.getRequiredApi("get_encoded_string")+"?orderId="+orderId;
    this.requestPayload.url = url;
    return this.commonService.getData(this.requestPayload);
  }

  getDisplayAdEncodeString(orderId){
    let url:any = this.environment.getRequiredApi("get_diplayAd_encoded_string")+"?orderId="+orderId;
    this.requestPayload.url = url;
    return this.commonService.getData(this.requestPayload);
  }

  sendEmailToSchedulingTeams(orderId){
    let url:any = this.environment.getRequiredApi("send_email_schedulin_team")+"?orderId="+orderId;
    this.requestPayload.url = url;
    return this.commonService.getData(this.requestPayload);
  }

  sendDisplayEmailToSchedulingTeams(orderId){
    let url:any = this.environment.getRequiredApi("send_display_email_schedulin_team")+"?orderId="+orderId;
    this.requestPayload.url = url;
    return this.commonService.getData(this.requestPayload);
  }
  updateDownloadStatus(itemId){
    const url = this.environment.getRequiredApi("upload_download_status")+"?itemId="+itemId;
    this.requestPayload.url = url;
    return this.commonService.getData(this.requestPayload);
   }
}

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { LocalService } from 'src/app/services/local.service';
import { PaymentService } from 'src/app/services/payment.service';

@Component({
  selector: 'app-rms-payment-checkout',
  templateUrl: './rms-payment-checkout.component.html',
  styleUrls: ['./rms-payment-checkout.component.scss']
})
export class RmsPaymentCheckoutComponent implements OnInit{
  cartItemsData:any;
  
  constructor(private paymentService:PaymentService,private route: ActivatedRoute,
    private cookieService:CookieService,private localService:LocalService){
    }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      this.cartItemsData = {
        orderType:1,
        orderDetails: {
          orderId: params['orderId'],
          paymentChildId: params['paymentChildId'],
          grandTotal: parseFloat(params['amount'])
        },
        items: [
          {
            itemId: params['itemId'],
            adId: params['adId']
          }
        ]
      };
      this.makePayment();
    });
  }
  makePayment(){
    this.paymentService.billDeskCreateOrder(this.cartItemsData);
  }
}

<div class="sidenav-list">
    <button class="close-btn"(click)="closeNavBar()">
        <mat-icon>close</mat-icon>
    </button>
    <ul class="nav navbar-nav ml-auto pull-left menu-snippet">
        <ng-container>
            <ng-container *ngFor="let list of menuBarRes.accessObjs; let i=index;">
                <ng-container *ngIf="!list.childObjs">
                    <li class="nav-item dropdown menu-item" *ngIf="list.permissionLevel==1">
                            <a class="nav-link-item" href="javascript:void(0)" role="button" (click)="setAccessObject(list.parentObjId,list)" [routerLinkActive]="['active']" [routerLink]="[list.navLink]">
                                <i class="fa {{list.menuIcon}} mr-1"></i>
                                <span class="primary-btn">{{list.parentObjName}}</span>
                            </a>
                    </li>
                </ng-container>
                <ng-container *ngIf="list.childObjs && list.childObjs.length>0">
                    <li class="nav-item dropdown menu-item" *ngIf="list.permissionLevel==1">
                        <a class="nav-link child-link" #button href="javascript:void(0)" role="button"
                        [matMenuTriggerFor]="levelOne" 
                        #levelOneTrigger="matMenuTrigger"  
                        style="z-index:1050">
                            <i class="fa {{list.menuIcon}} profile-icon"></i> 
                            <span class="pl-2 pr-2 parent-menu">{{list.parentObjName}}</span>
                            <i class="fa fa-angle-down-icon pl-1 menu-icon"></i>
                        </a>
                        <mat-menu #levelOne="matMenu" direction="down" yPosition="below">
                            <span (mouseenter)="menuenter()" (mouseleave)="menuLeave(levelOneTrigger, button)">
                                <ng-container *ngFor="let childs of list.childObjs">
                                    <ng-container *ngIf="childs.permissionLevel==1">
                                        <a class="dropdown-item horizon-list"
                                        (click)="setAccessObject(childs.objId,childs)" [routerLinkActive]="['active']" [routerLink]="[childs.navLink]"
                                        href="javascript:void(0)">
                                            <i class="fa {{childs.menuIcon}} us-icon"></i>
                                            <span class="menu-text">{{childs.objName}}</span>
                                        </a>
                                    </ng-container>
                                </ng-container>
                            </span>
                        </mat-menu>
                    </li>
                </ng-container>
            </ng-container>
        </ng-container>
    </ul>
</div>
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class GlobalMessagesService {

  loginFormValidations:any={
    "userType_required":"Please select usertype",
    "email_required":"Please enter User Name",
    "email_user_required":"Please enter Email Id",
    "email_validation":"Please enter valid email id",
    "password_required":"Please enter password",
    "reg_invalid_token": "You were already registered using this link. Please login..",
    "in_memory_token": "Already registered using this link",
    "user_required":"Please enter username",
  }
  resetPwd:any={
    "error_passwords_match":"New Password and Confirm Password do not match.",
    
  }
  userManagement:any={
    "plant_required":"Please select plant name",
    "role_required":"Please select role",
    "firstname_required":"Please enter first name",
    "lastname_required":"Please enter last name",
    "mobile_required":"Please enter mobile number",
    "mobile_validation":"Please enter a valid mobile number",
    "email_required":"Please enter email id",
    "email_validation":"Please enter valid email id",
    "RMS_restrict": "You cannot Admin other than RMS type.Please select other Role",
    "user_existed": "User is already assigned to the same plant/terminal' with same role",
    "inactive_user": "User is Inactive.Please contact Administrator",
    "user_existed_add": "User already existed.You can add to this Business Partner",
    "user_added": "User is successfully added",
    "edit_user": "User is successfully updated",
    "user_delete": "User is successfully removed",
  }
  roleManagement:any={
    "roleName":"Please enter RoleName"
  }
  managePlant:any={
    "bpName_required":"Please enter  Plant/Terminal/ALDS Name",
    "bpType_required":"Please enter  Plant/Terminal/ALDS Type",
    "bp_required":"Please select Business Partner ",
    "zipcode_required":"PinCode is required",
    "phone_required":"Phone number is invalid",
    "error_branch_GSTN_invalid":"Branch GSTIN is invalid",
    "pan_invalid":"PAN number is invalid",
    "error_branch_zip_invalid":"pincode is invalid",
    "error_branch_drugLicNum":"Please enter Drug License number",
    "addressLine1_req":"Please enter Address Line",
    "GSTIN_req":"Please enter GSTIN number",
    "PAN_req":"Please enter PAN number",
    "GSTIN_valid":"Please enter valid GSTIN number",
    "PAN_valid":"Please enter valid PAN number",
    "Plant_req":"Please enter Plant code",
    "Mobile_req":"Please enter mobile number",
    "Mobile_valid":"Please enter valid mobile number",
    "country_req":"Please select country",
    "state_req":"Please select state",
    "city_req":"Please select city",
    
    
  }
  check_box_error:any ={
    "select_msg":"Please select only one ",  
    "select_atleast_msg":"Please select atleast one ",  
  }
  configError:any={
      "group_name":"Please enter Group Name",
      "desc_req":"Please enter Description",
      "parent_grp": "Please enter parent group",
      "status":"Please select config group status"
  }
  loginHistory:any={
    "login_req":"Please enter login id",
    "frmDate":"Please select from date",
    "toDate":"Please select to date",
  }

  rolesHomepage:any={
    L4:"/dashboard",
    QM:"/dashboard",
    CS:"/dashboard",
    ADMIN:"/dashboard",
    DEALER_ADMIN:"/dashboard",
    NETWORK_ADMIN:'/dashboard',
    RC:"/dashboard",
    CSHQ:"/dashboard",
    CSFQ:"/dashboard",
    GD:"/dashboard",
    VP:"/dashboard"
  }
  actionCounts:any={
    "/fieldFix/releaseFieldFix":['VIEW_CSHQ_RELEASED','VIEW_RELSD'],
    "/fieldFix/savedFieldFix":['VIEW_SAVED'],
    "/fieldFix/archivedFieldFix":['VIEW_ARCVD'],
    "/fieldFix/pendingAprFieldFix":['VIEW_PFAPL'],
    "/fieldFix/returnedFieldFix":['VIEW_RETRN'],
    "/fieldFix/readyToRelease":['VIEW_REDRL'],
    "/fieldFix/onHoldFieldFix":['VIEW_FREEZ'],
    "/fieldFix/newFieldFixQM":['VIEW_CSHQ_NEW'],
    "/fieldFix/newFieldFixCSHQ":['VIEW_RC_NEW'],
    "/fieldFix/releaseFieldFixGD":['VIEW_RC_RELEASED_GD'],
    "/fieldFix/limitedFieldFixRC":['VIEW_RC_LIMTED_RC'],
    "/fieldFix/ffProgressUpdate":['VIEW_RC_FF_PRG_UPD']
  }
  constructor() {
    
   }
  
  headerList:any={
    Manage_User_List1:{
      "userName":"Username",
      "logonId":"Email ID",      
      "mobile":"Mobile",
      "roleId":"Role",
      "address":"Address",
      "orgName":"Org Name",
      "opu":"Operational Unit"
    },
    Field_Fix_VIN_LIST:{
      "vehicleModel":"Model",
      "vin":"VIN",
      "countryName":"Country",
      "wscCode":"wsc_code"
    },
    Field_Fix_VIN_LIST_ALL:{
      "rcName":"Region Center",
      "vehicleModel":"Model",
      "vin":"VIN",
      "countryName":"Country",
      "wscCode":"wsc_code"
    }
  }
}

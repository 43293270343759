<div class="dailog-container">
    <button mat-button mat-dialog-close class="close-btn">&times;</button>
    <h1 mat-dialog-title>{{data.title}}</h1>
    <div mat-dialog-content class="dailog-content-block">
        <i class="fa fa-exclamation-circle warning-icon"></i> 
        <div class="warning-text">{{data.message}}</div>
    </div>
    <div mat-dialog-actions align="end" class="dailog-actions-block">
        <button mat-button mat-dialog-close>
            {{data.cancelText}}
        </button>
        <button mat-raised-button color="primary" mat-dialog-close (click)="data.callBack(true)" class="footer-primary-btn">{{data.confirmText}}</button>
    </div>
</div>
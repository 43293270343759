<div class="app-body mt-0">
    <div class="col-md-12 row m-0 p-0 login-main-block">
        <div class="login-block">
            <div class="col-md-12 p-0">
                <div class="top-row">
                    <div class="logo-header">
                        <img src="./assets/images/logo.png" class="header-left-img" />
                    </div>
                    <div class="form-title">Sign Up</div>
                </div>
                <div class="form-div-container">
                    <form [formGroup]="registationForm" autocomplete="off">
                        <div class="form-container">
                            <div class="form-field">
                                <!-- <label class="form-label">Logon Name:<span class="asteric">*</span></label> -->
                                <label class="form-label">User Id:<span class="asteric">*</span></label>
                                <input class="form-control field-input" name="logonId" formControlName="logonId"/>
                                <div class="mat-error">
                                    <ng-container *ngIf="registationForm.controls['logonId'].touched && 
                                        registationForm.controls['logonId'].invalid && registationForm.controls['logonId'].dirty">
                                            <span *ngIf="(registationForm.controls['logonId'].hasError('required'))"> Please enter Logon Name.</span>
                                            <span *ngIf="!registationForm.controls['logonId'].hasError('required') && registationForm.controls['logonId'].hasError('noSpaceAllowed')">Whitespace not allowed</span>
                                    </ng-container>
                                </div>
                            </div>
                            <div class="form-field">
                                <label class="form-label">Mobile Number:<span class="asteric">*</span></label>
                                <input class="form-control field-input" (keypress)="numbersOnly($event)" name="mobile" formControlName="mobile"/>
                                <div class="mat-error">
                                    <ng-container *ngIf="registationForm.controls['mobile'].touched && 
                                        registationForm.controls['mobile'].invalid && registationForm.controls['mobile'].dirty">
                                        <span *ngIf="(registationForm.controls['mobile'].hasError('required'))"> Please enter Mobile Number.</span>
                                        <span *ngIf="(!registationForm.controls['mobile'].hasError('required') && registationForm.controls['mobile'].hasError('pattern'))"> Please enter valid Mobile Number.</span>
                                    </ng-container>
                                </div>
                            </div>
                            <div class="form-field">
                                <label class="form-label">Registered Email:<span class="asteric">*</span></label>
                                <input class="form-control field-input" name="email" formControlName="email"/>
                                <div class="mat-error">
                                    <ng-container *ngIf="registationForm.controls['email'].touched && 
                                        registationForm.controls['email'].invalid && registationForm.controls['email'].dirty">
                                        <span *ngIf="(registationForm.controls['email'].hasError('required'))"> Please enter Email.</span>
                                        <span *ngIf="(!registationForm.controls['email'].hasError('required') && registationForm.controls['email'].hasError('pattern'))"> Please enter valid Email.</span>
                                    </ng-container>
                                </div>
                            </div>
                        </div>
                        <div class="form-container">
                            <div class="form-field">
                                <label class="form-label">Name:<span class="asteric">*</span></label>
                                <input class="form-control field-input" name="firstName" formControlName="firstName"/>
                                <div class="mat-error">
                                    <ng-container *ngIf="registationForm.controls['firstName'].touched && 
                                        registationForm.controls['firstName'].invalid && registationForm.controls['firstName'].dirty">
                                        <span *ngIf="registationForm.controls['firstName'].hasError('required')">Name is required</span>
                                        <span *ngIf="(!registationForm.controls['firstName'].hasError('required') && registationForm.controls['firstName'].hasError('noSpaceAllowed'))">Whitespace not allowed</span>
                                        <span *ngIf="(!registationForm.controls['firstName'].hasError('required') && !registationForm.controls['firstName'].hasError('noSpaceAllowed') && registationForm.controls['firstName'].hasError('maxlength'))">Name must be below 35 charaters</span>
                                    </ng-container>
                                </div>
                            </div>
                            <div class="form-field">
                                <label class="form-label">House Number <span class="asteric">*</span></label>
                                <input type="text" name="houseNo" formControlName="houseNo" 
                                [attr.disabled]="readonly ? true : null"
                                [ngClass]="{'is-invalid' : ((registationForm.controls['houseNo'].dirty && (registationForm.controls['houseNo'].invalid)))}"
                                class="form-control field-input" />
                                <div class="mat-error">
                                    <ng-container *ngIf="registationForm.controls['houseNo'].touched && 
                                    registationForm.controls['houseNo'].invalid && registationForm.controls['houseNo'].dirty">
                                    <span *ngIf="registationForm.controls['houseNo'].hasError('required')">House number is required</span>
                                    <span *ngIf="(!registationForm.controls['houseNo'].hasError('required') && registationForm.controls['houseNo'].hasError('pattern') && registationForm.controls['houseNo'].hasError('maxlength'))">House number must be below 10 characters</span>
                                    <span *ngIf="(!registationForm.controls['houseNo'].hasError('required') && !registationForm.controls['houseNo'].hasError('maxlength') && registationForm.controls['houseNo'].hasError('pattern'))">House number must strat with number</span>
                                                    <!-- <small>House Number is required</small> -->
                                    </ng-container>
                                </div>
                            </div>
                            <div class="form-field">
                                <label class="form-label">Pincode <span class="asteric">*</span></label>
                                <input type="text" name="pincode" (keypress)="numbersOnly($event)" formControlName="pinCode" 
                                [ngClass]="{'is-invalid' : ((registationForm.controls['pinCode'].dirty && (registationForm.controls['pinCode'].invalid)))}"
                                class="form-control field-input" />
                                <div class="mat-error">
                                    <ng-container *ngIf="registationForm.controls['pinCode'].touched && 
                                        registationForm.controls['pinCode'].invalid ">
                                        <span *ngIf="registationForm.controls['pinCode'].hasError('required')">Pincode is required</span>
                                        <span *ngIf="registationForm.controls['pinCode'].hasError('maxlength')">Pincode must be below 6 charaters</span>
                                        <span *ngIf="registationForm.controls['pinCode'].hasError('minlength')">Pincode must be 6 charaters</span>
                                                    <!-- <small>Pincode is required</small> -->
                                    </ng-container>
                                </div>
                            </div>
                            <!-- <div class="form-field">
                                <label class="form-label">Middle Name:</label>
                                <input class="form-control field-input" name="middleName" formControlName="middleName"/>
                                <div class="mat-error">
                                    <ng-container *ngIf="registationForm.controls['middleName'].touched && 
                                        registationForm.controls['middleName'].invalid && registationForm.controls['middleName'].dirty">
                                        <span *ngIf="(registationForm.controls['middleName'].hasError('required'))"> Please enter Middle Name.</span>
                                    </ng-container>
                                </div>
                            </div> -->
                            <!-- <div class="form-field">
                                <label class="form-label">Last Name:</label>
                                <input class="form-control field-input" name="lastName" formControlName="lastName"/>
                                <div class="mat-error">
                                    <ng-container *ngIf="registationForm.controls['lastName'].touched && 
                                        registationForm.controls['lastName'].invalid && registationForm.controls['lastName'].dirty">
                                        <span *ngIf="(registationForm.controls['lastName'].hasError('required'))"> Please enter Last Name.</span>
                                        <span *ngIf="!registationForm.controls['lastName'].hasError('required') && registationForm.controls['lastName'].hasError('noSpaceAllowed')">Whitespace not allowed</span>
                                    </ng-container>
                                </div>
                            </div> -->
                        </div>
                        <div class="form-container">
                            <div class="form-field">
                                <label class="form-label">Street Name <span class="asteric">*</span></label>
                                <input type="text" name="address1" formControlName="address1" 
                                    [attr.disabled]="readonly ? true : null"
                                    [ngClass]="{'is-invalid' : ((registationForm.controls['address1'].dirty && (registationForm.controls['address1'].invalid)))}"
                                    class="form-control field-input"/>
                                <div class="mat-error">
                                    <ng-container *ngIf="registationForm.controls['address1'].touched && 
                                        registationForm.controls['address1'].invalid && registationForm.controls['address1'].dirty">
                                        <span *ngIf="registationForm.controls['address1'].hasError('required')">Street Name is required</span>
                                        <span *ngIf="(!registationForm.controls['address1'].hasError('required') && registationForm.controls['address1'].hasError('noSpaceAllowed'))">Whitespace not allowed</span>
                                        <span *ngIf="(!registationForm.controls['address1'].hasError('required') && !registationForm.controls['address1'].hasError('noSpaceAllowed') && registationForm.controls['address1'].hasError('maxlength'))">Street Name must be below 35 charaters</span>
                                                    <!-- <small>Street Name is required</small> -->
                                    </ng-container>
                                </div>
                            </div>
                            <div class="form-field">
                                <label class="form-label">State <span class="asteric">*</span></label>
                                <select name="city" (change) = "filterCityOnState(registationForm.controls['state'].value)"
                                        [ngClass]="{'is-invalid' : ((registationForm.controls['state'].dirty && (registationForm.controls['state'].invalid)))}"
                                        class="form-control field-input" formControlName="state">
                                            <option value="">Select</option>
                                            <option [value]="st.stateCode" *ngFor="let st of statesList;">{{st.state}}</option>
                                        </select>
                                <div class="mat-error">
                                    <ng-container *ngIf="registationForm.controls['state'].touched && 
                                        registationForm.controls['state'].invalid && registationForm.controls['state'].dirty">
                                                        <small>State is required</small>
                                        </ng-container>
                                </div>
                            </div>
                            <div class="form-field">
                                <label class="form-label">City <span class="asteric">*</span></label>
                                <select name="city" 
                                        [ngClass]="{'is-invalid' : ((registationForm.controls['city'].dirty && (registationForm.controls['city'].invalid)))}"
                                        class="form-control field-input" formControlName="city">
                                            <option value="">Select</option>
                                            <option [value]="list.bookingCode" *ngFor="let list of filterdCityList;">{{list.bookingLocation}}</option>
                                        </select>
                                <div class="mat-error">
                                    <ng-container *ngIf="registationForm.controls['city'].touched && 
                                        registationForm.controls['city'].invalid && registationForm.controls['city'].dirty">
                                                        <small>City is required</small>
                                        </ng-container>
                                </div>
                            </div>
                            <!-- <div class="form-field">
                                <label class="form-label">Street Name <span class="asteric">*</span></label>
                                <textarea name="address1" rows="2" cols="5" formControlName="address1" 
                                    [attr.disabled]="readonly ? true : null"
                                    [ngClass]="{'is-invalid' : ((registationForm.controls['address1'].dirty && (registationForm.controls['address1'].invalid)))}"
                                    class="form-control field-textarea">
                                </textarea>
                                <div class="mat-error">
                                    <ng-container *ngIf="registationForm.controls['address1'].touched && 
                                        registationForm.controls['address1'].invalid && registationForm.controls['address1'].dirty">
                                        <span *ngIf="registationForm.controls['address1'].hasError('required')">Street Name is required</span>
                                        <span *ngIf="(!registationForm.controls['address1'].hasError('required') && registationForm.controls['address1'].hasError('noSpaceAllowed'))">Whitespace not allowed</span>
                                        <span *ngIf="(!registationForm.controls['address1'].hasError('required') && !registationForm.controls['address1'].hasError('noSpaceAllowed') && registationForm.controls['address1'].hasError('maxlength'))">Street Name must be below 35 charaters</span> -->
                                                    <!-- <small>Street Name is required</small> -->
                                    <!-- </ng-container>
                                </div>
                            </div> -->
                            <!-- <div class="form-field">
                                <label class="form-label">Address 2</label>
                                <textarea name="address1" rows="2" cols="5" formControlName="address2" 
                                    [attr.disabled]="readonly ? true : null"
                                    [ngClass]="{'is-invalid' : ((registationForm.controls['address2'].dirty && (registationForm.controls['address2'].invalid)))}"
                                    class="form-control field-textarea">
                                </textarea>
                                <div class="mat-error">
                                    <ng-container *ngIf="registationForm.controls['address2'].touched && 
                                        registationForm.controls['address2'].invalid && registationForm.controls['address2'].dirty">
                                                    <small>address1 is required</small>
                                    </ng-container>
                                </div>
                            </div> -->
                            <!-- <div class="form-field">
                                <label class="form-label">Address 3</label>
                                <textarea name="address3" rows="2" cols="5" formControlName="address3" 
                                    [attr.disabled]="readonly ? true : null"
                                    [ngClass]="{'is-invalid' : ((registationForm.controls['address3'].dirty && (registationForm.controls['address3'].invalid)))}"
                                    class="form-control field-textarea">
                                </textarea>
                                <div class="mat-error">
                                    <ng-container *ngIf="registationForm.controls['address3'].touched && 
                                        registationForm.controls['address3'].invalid && registationForm.controls['address3'].dirty">
                                                    <small>address3 is required</small>
                                    </ng-container>
                                </div>
                            </div> -->
                        </div>
                        <div class="form-container">
                            <!-- <div class="form-field">
                                <label class="form-label">House Number <span class="asteric">*</span></label>
                                <input type="text" name="houseNo" formControlName="houseNo" 
                                [attr.disabled]="readonly ? true : null"
                                [ngClass]="{'is-invalid' : ((registationForm.controls['houseNo'].dirty && (registationForm.controls['houseNo'].invalid)))}"
                                class="form-control field-input" />
                                <div class="mat-error">
                                    <ng-container *ngIf="registationForm.controls['houseNo'].touched && 
                                    registationForm.controls['houseNo'].invalid && registationForm.controls['houseNo'].dirty">
                                    <span *ngIf="registationForm.controls['houseNo'].hasError('required')">House number is required</span>
                                    <span *ngIf="(!registationForm.controls['houseNo'].hasError('required') && registationForm.controls['houseNo'].hasError('pattern') && registationForm.controls['houseNo'].hasError('maxlength'))">House number must be below 10 characters</span>
                                    <span *ngIf="(!registationForm.controls['houseNo'].hasError('required') && !registationForm.controls['houseNo'].hasError('maxlength') && registationForm.controls['houseNo'].hasError('pattern'))">House number must strat with number</span> -->
                                                    <!-- <small>House Number is required</small> -->
                                    <!-- </ng-container>
                                </div>
                            </div> -->
                            <!-- <div class="form-field">
                                <label class="form-label">Office Location <span class="asteric">*</span></label>
                                <input type="text" name="officeLocation" formControlName="officeLocation" 
                                [attr.disabled]="readonly ? true : null"
                                [ngClass]="{'is-invalid' : ((registationForm.controls['officeLocation'].dirty && (registationForm.controls['officeLocation'].invalid)))}"
                                class="form-control field-input" />
                                <div class="mat-error">
                                    <ng-container *ngIf="registationForm.controls['officeLocation'].touched && 
                                    registationForm.controls['officeLocation'].invalid && registationForm.controls['officeLocation'].dirty">
                                                    <small>Location is required</small>
                                    </ng-container>
                                </div>
                            </div> -->
                            <div class="form-field">
                                <label class="form-label">GST Number</label>
                                        <input type="text" name="gst" formControlName="gstNo" 
                                        [attr.disabled]="readonly ? true : null"
                                        [ngClass]="{'is-invalid' : ((registationForm.controls['gstNo'].dirty && (registationForm.controls['gstNo'].invalid)))}"
                                        class="form-control field-input" />
                                        <div class="mat-error">
                                            <ng-container *ngIf="registationForm.controls['gstNo'].touched && 
                                            registationForm.controls['gstNo'].invalid && registationForm.controls['gstNo'].dirty">
                                                            <small>Gst is required</small>
                                            </ng-container>
                                        </div>
                            </div>
                            <div class="form-field">
                                <label class="form-label">PAN Number</label>
                                        <input type="text" name="pincode" formControlName="panNumber" 
                                        [attr.disabled]="readonly ? true : null"
                                        [ngClass]="{'is-invalid' : ((registationForm.controls['panNumber'].dirty && (registationForm.controls['panNumber'].invalid)))}"
                                        class="form-control field-input" />
                                        <div class="mat-error">
                                            <ng-container *ngIf="registationForm.controls['panNumber'].touched && 
                                            registationForm.controls['panNumber'].invalid && registationForm.controls['panNumber'].dirty">
                                                            <small>Pan is required</small>
                                            </ng-container>
                                        </div>
                            </div>
                            <div class="form-field">
                                <label class="form-label">Aadhar Number </label>
                                <input type="text" name="aadharNumber" formControlName="aadharNumber" (keypress)="numbersOnly($event)"
                                [ngClass]="{'is-invalid' : ((registationForm.controls['aadharNumber'].dirty && (registationForm.controls['aadharNumber'].invalid)))}"
                                class="form-control field-input" />
                                <div class="mat-error">
                                    <ng-container *ngIf="registationForm.controls['aadharNumber'].touched && 
                                    registationForm.controls['aadharNumber'].invalid && registationForm.controls['aadharNumber'].dirty">
                                    <span *ngIf="registationForm.controls['aadharNumber'].hasError('required')">aadhar is required</span>
                                                <span *ngIf="registationForm.controls['aadharNumber'].hasError('pattern')">aadhar must be 12 numbers</span>
                                                    <!-- <small>aadhar is required</small> -->
                                    </ng-container>
                                </div>
                            </div>
                            <!-- <div class="form-field">
                                <label class="form-label">Pincode <span class="asteric">*</span></label>
                                <input type="text" name="pincode" formControlName="pinCode" 
                                    (keypress)="numbersOnly($event)"
                                    [attr.disabled]="readonly ? true : null"
                                    [ngClass]="{'is-invalid' : ((registationForm.controls['pinCode'].dirty && (registationForm.controls['pinCode'].invalid)))}"
                                    class="form-control field-input" />
                                    <div class="mat-error">
                                        <ng-container *ngIf="registationForm.controls['pinCode'].touched && 
                                        registationForm.controls['pinCode'].invalid && registationForm.controls['pinCode'].dirty">
                                        <span *ngIf="registationForm.controls['pinCode'].hasError('required')">Pincode is required</span>
                                        <span *ngIf="(!registationForm.controls['pinCode'].hasError('required') && registationForm.controls['pinCode'].hasError('pattern'))">Please enter valid pincode</span>
                                        <span *ngIf="(!registationForm.controls['pinCode'].hasError('required') && !registationForm.controls['pinCode'].hasError('noSpaceAllowed') && registationForm.controls['pinCode'].hasError('maxlength'))">Pincode must be below 6 charaters</span>
                                        </ng-container>
                                    </div>
                            </div> -->
                        </div>
                        <!-- <div class="form-container">
                            <div class="form-field">
                                <label class="form-label">PAN Number</label>
                                        <input type="text" name="pincode" formControlName="panNumber" 
                                        [attr.disabled]="readonly ? true : null"
                                        [ngClass]="{'is-invalid' : ((registationForm.controls['panNumber'].dirty && (registationForm.controls['panNumber'].invalid)))}"
                                        class="form-control field-input" />
                                        <div class="mat-error">
                                            <ng-container *ngIf="registationForm.controls['panNumber'].touched && 
                                            registationForm.controls['panNumber'].invalid && registationForm.controls['panNumber'].dirty">
                                                            <small>Pan is required</small>
                                            </ng-container>
                                        </div>
                            </div> -->
                        
                            <!-- <div class="form-field">
                                <label class="form-label">State <span class="asteric">*</span></label>
                                <select name="city" (change) = "filterCityOnState(registationForm.controls['state'].value)"
                                        [ngClass]="{'is-invalid' : ((registationForm.controls['state'].dirty && (registationForm.controls['state'].invalid)))}"
                                        class="form-control field-input" formControlName="state">
                                            <option value="">Select</option>
                                            <option [value]="st.stateCode" *ngFor="let st of statesList;">{{st.state}}</option>
                                        </select>
                                <div class="mat-error">
                                    <ng-container *ngIf="registationForm.controls['state'].touched && 
                                        registationForm.controls['state'].invalid && registationForm.controls['state'].dirty">
                                                        <small>State is required</small>
                                        </ng-container>
                                </div>
                            </div> -->
                            <!-- <div class="form-field">
                                <label class="form-label">City <span class="asteric">*</span></label>
                                <select name="city" 
                                        [ngClass]="{'is-invalid' : ((registationForm.controls['city'].dirty && (registationForm.controls['city'].invalid)))}"
                                        class="form-control field-input" formControlName="city">
                                            <option value="">Select</option>
                                            <option [value]="st.id" *ngFor="let st of filterdCityList;">{{st.city}}</option>
                                        </select>
                                <div class="mat-error">
                                    <ng-container *ngIf="registationForm.controls['city'].touched && 
                                        registationForm.controls['city'].invalid && registationForm.controls['city'].dirty">
                                                        <small>City is required</small>
                                        </ng-container>
                                </div>
                            </div> -->
                            <!-- <div class="form-field">
                                <label class="form-label">City <span class="asteric">*</span></label>
                                <select name="city" 
                                        [ngClass]="{'is-invalid' : ((registationForm.controls['city'].dirty && (registationForm.controls['city'].invalid)))}"
                                        class="form-control field-input" formControlName="city">
                                            <option value="">Select</option>
                                            <option [value]="list.bookingCode" *ngFor="let list of filterdCityList;">{{list.bookingLocation}}</option>
                                        </select>
                                <div class="mat-error">
                                    <ng-container *ngIf="registationForm.controls['city'].touched && 
                                        registationForm.controls['city'].invalid && registationForm.controls['city'].dirty">
                                                        <small>City is required</small>
                                        </ng-container>
                                </div>
                            </div> -->
                        <!-- </div> -->
                        <!-- <div class="form-container">
                            <div class="form-field">
                                <label class="form-label">GST Number</label>
                                        <input type="text" name="gst" formControlName="gstNo" 
                                        [attr.disabled]="readonly ? true : null"
                                        [ngClass]="{'is-invalid' : ((registationForm.controls['gstNo'].dirty && (registationForm.controls['gstNo'].invalid)))}"
                                        class="form-control field-input" />
                                        <div class="mat-error">
                                            <ng-container *ngIf="registationForm.controls['gstNo'].touched && 
                                            registationForm.controls['gstNo'].invalid && registationForm.controls['gstNo'].dirty">
                                                            <small>Gst is required</small>
                                            </ng-container>
                                        </div>
                            </div>
                        </div> -->
                    <div class="login-footer">
                        <div class="captcha-label">Enter the captcha shown below:</div>
                        <div class="footer-container">
                            <div class="captcha-block">
                                <div class="pull-left image-block">
                                    <img [src]="url" class="captcha-image" alt="verify captcha"/>
                                    <div class="refresh-icon" (click)="checkCaptcha()"><i class="fa fa-refresh fa-refresh-icon"></i></div>
                                </div>
                                <div class="pull-left">
                                    <input type="text" class="form-control captcha-input" formControlName="captcha" placeholder="Type Captcha here"/>
                                    <div class="mat-error" style="height:20px;">
                                      <ng-container
                                      *ngIf="registationForm.controls['captcha'].touched && 
                                        registationForm.controls['captcha'].invalid && registationForm.controls['captcha'].dirty">
                                                   <small>Please enter captcha</small>
                                    </ng-container>
                                  </div>  
                                </div>
                            </div>
                            <div class="buttons-block">
                                <button mat-raised-button color="primary" [disabled]="readonly || registationForm.invalid"  class="primary-action-button mr-2" (click)="verifyCaptcha()">Register</button>
                                <button mat-raised-button class="btn btn-primary register-btn" (click)="navigateLoginPage()">Login</button>
                            </div>
                        </div>
                    </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>

<!--Confirmation Modal-->
<div id="confirmModal" class="modal" class="modal" role="dialog" data-backdrop="static" data-keyboard="false">
    <div class="modal-dialog modal-sm confirm-sm">
  
      <div class="modal-content">
        <div class="modal-header confirm-header">
            <h4 class="modal-title">Successfully Registered!</h4>
          <!-- <button type="button" class="close" (click)="closeModal('confirmModal')">&times;</button> -->
        </div>
        <div class="modal-body pt-0">
          <div class="warning-block">
            <i class="fa fa-check-circle success-icon"></i>
          </div>
          <div class="warning-text">
               Registration has been completed! Please check the mail to complete further authentication process.
          </div>
          <div class="footer-buttons">
            <!-- <button class="btn cancel-btn mr-4" (click)="closeModal('confirmModal')">Cancel</button> -->
            <button class="btn save-btn" (click)="closeModal('confirmModal')">Ok</button>
          </div>
        </div>
      </div>
    </div>
  </div>
<app-footer></app-footer>

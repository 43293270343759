<header class="app-header navbar pl-1">
    <div>
    <a class="navbar-brand" href="javascript:void(0)">
        <img class="navbar-brand-full" id="headerLogo" src="./assets/images/u3.png"  alt="Sakshi EClassifieds" />
    </a>
    <button class="navbar-toggler sidebar-toggler" (click)="openSidenav()"  id="left-toggle-btn"  type="button" data-toggle="sidebar-lg-show">
        <mat-icon>menu</mat-icon>
    </button>
    </div>
    <div class="menu-snippet-1">
        <app-horizontal-menu-bar [menuBarRes]="menuBarRes" [isLanded]="isLanded" [userRes]="userRes"></app-horizontal-menu-bar>
    </div>
</header>
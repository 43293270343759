import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { CookieService } from 'ngx-cookie-service';
import { LoaderService } from '../components/loader/loader.service';
import { ApiConfigurationService } from './api-configuration.service';
import { AuthenticationService } from './authentication.service';
import { LocalService } from './local.service';

@Injectable({
  providedIn: 'root'
})
export class HTTPErrorInterceptorService implements HttpInterceptor{

  constructor(private toastrService:ToastrService,private router:Router,private cookieService:CookieService,private loaderService:LoaderService,
    private authenticationService:AuthenticationService,private environment:ApiConfigurationService, private localService:LocalService) { }
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(catchError(err => {
        console.log(err.status);
        this.toastrService.clear();
        if (err.status === 401) {
            // auto logout if 401 response returned from api
            if(this.cookieService.get('token')=='' || err.url.includes('revoke-token')){
              this.localService.clearToken();
              this.loaderService.loadHide();
              this.cookieService.deleteAll();
              sessionStorage.setItem('logout','1');
              this.router.navigate(['/']);
            }
            else if(this.router.url!='/' && this.router.url!='/login' && this.cookieService.get('token')!=''){
              let logoutFormUrl:any=this.environment.getRequiredApi("user_logout").replace('/v1','')+"?";
              let requestPayload:any=Object();
              requestPayload.url=logoutFormUrl;
              this.authenticationService.logout(requestPayload).subscribe(res=>{
                this.router.navigate(['/']);
                this.localService.clearToken();
                this.loaderService.loadHide();
                sessionStorage.setItem('logout','1');
              });
            }
            if(err.error.hasOwnProperty('error_description')){
              // this.toastrService.error(err.error.error_description);
              if(err.error.error_description!='You have logged in on another window or device, confirm to logout all active sessions and continue to login.'){
                this.toastrService.error("Session expired, Please login");
              }
              this.loaderService.loadHide();
            }else{
              if(err.error.error=='Unauthorized'){
                // this.toastrService.error(err.error.message);
                this.toastrService.error("Incorrect UserName or Password");
                this.loaderService.loadHide();
              }
            }
        }else if(err.status === 0){
          if(err.statusText=='Unknown Error'){
            this.toastrService.clear();
            let error:any = (err.message!=undefined && err.message!=null) ? err.message : 'Unable to process the request for now, please contact your administration.'
            this.toastrService.error(error);
            this.loaderService.loadHide();
          }else{
            this.router.navigate(['/']);
            this.localService.clearToken();
            this.loaderService.loadHide();
            sessionStorage.setItem('logout','1');
          }
          
        }else{
          if(this.router.url!='/' && this.router.url!='/login' && Object.keys(err).length>0){
            if(err.error.hasOwnProperty('message')){
              this.toastrService.clear();
              let error:any = (err.error.message && err.error.message!=undefined && err.error.message!=null) ? err.message : 'Unable to process the request for now, please contact your administration.'
              this.toastrService.error(error);
              this.loaderService.loadHide();
            }else{
              this.toastrService.clear();
              let error:any = (err.error.error_description && err.error.error_description!=undefined && err.error.error_description!=null) ? err.message : 'Unable to process the request for now, please contact your administration.'
              this.toastrService.error(error);
              this.loaderService.loadHide();
            }
          }          
        }
        this.loaderService.loadHide();
        let error = err;
        if(Object.keys(err).length>0){
          error = err.error.error_description || err.error.message;
        }
        return throwError(error);
    }));
}
}
